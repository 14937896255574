import React from "react";

import { useOutletContext, useParams } from "react-router-dom";

import JobApplicationsDataTable from "@components/DataTable/JobApplicationsDataTable";
import Paper from "@components/Paper";
import Typography from "@components/Typography";

import JobProfile from "@interfaces/database/JobProfile";

import { resolveMultiLingual } from "@utils/multiLingual";
import { intl } from "@utils/translate";

const JobApplications = () => {
  const { job_id: jobId = "" } = useParams();
  const {
    jobData
  }: {
    jobData: JobProfile;
  } = useOutletContext();

  return (
    <Paper>
      <Typography variant="h3">{intl.get("t_general_applications")}</Typography>
      <br />
      <Typography variant="body1" mt={2.5} mb={3}>
        {intl.get("t_job_applications_subtitle", {
          jobTitle: resolveMultiLingual(jobData?.job_title)
        })}
      </Typography>
      <JobApplicationsDataTable jobId={jobId} />
    </Paper>
  );
};

export default JobApplications;

import { useEffect, useState } from "react";

import { Outlet, useNavigate, useParams } from "react-router-dom";

import { Grid } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import useForceUpdate from "use-force-update";

import SideMenu from "@components/SideMenu";
import Typography from "@components/Typography";

import JobProfile from "@interfaces/database/JobProfile";

import { FIRESTORE_COLLECTIONS } from "@utils/config";
import { db } from "@utils/firebase";
import { resolveMultiLingual } from "@utils/multiLingual";
import translate, { intl } from "@utils/translate";

const JobsSettings = () => {
  const { job_id: jobId } = useParams();
  const [jobData, setJobData] = useState<JobProfile>();
  const navigate = useNavigate();

  const menuLinks = [
    {
      label: intl.get("t_general_preview"),
      redirectLink: `/${translate.getCurrentLocale()}/employers/jobs/settings/${jobId}/preview`,
      aliasLinks: [`/employers/jobs/settings/${jobId}/preview`]
    },
    {
      label: intl.get("t_general_applications"),
      redirectLink: `/${translate.getCurrentLocale()}/employers/jobs/settings/${jobId}/applications`,
      aliasLinks: ["/employers/jobs/settings/${jobId}/applications"]
    },
    {
      label: intl.get("t_general_invitations"),
      redirectLink: `/${translate.getCurrentLocale()}/employers/jobs/settings/${jobId}/invitations`,
      aliasLinks: ["/employers/jobs/settings/${jobId}/invitations"]
    }
  ];

  const forceUpdate = useForceUpdate();
  const languageChanged = () => {
    forceUpdate();
  };

  useEffect(() => {
    translate.addLanguageChangedListener(languageChanged);
    return () => {
      translate.removeLanguageChangedListener(languageChanged);
    };
  }, []);

  useEffect(() => {
    if (jobId) {
      // fetch job data for edit operation
      const getJobData = async () => {
        const jobDocRef = doc(db, FIRESTORE_COLLECTIONS.JOBS, jobId);
        const jobDoc = await getDoc(jobDocRef);
        if (jobDoc.exists()) {
          setJobData(jobDoc.data() as JobProfile);
        } else {
          navigate("/dashboard");
        }
      };
      getJobData();
    }
  }, [jobId]);

  return (
    <>
      <Typography variant="h3" color="white">
        {resolveMultiLingual(jobData?.job_title)}
      </Typography>

      <Grid container mt={4} rowGap={3}>
        <Grid item xs={12} md={3} pr={{ xs: 0, md: 2 }}>
          <SideMenu menuLinks={menuLinks} />
        </Grid>
        <Grid item xs={12} md={9} pl={{ xs: 0, md: 2 }}>
          <Outlet context={{ jobData }} />
        </Grid>
      </Grid>
    </>
  );
};

export default JobsSettings;

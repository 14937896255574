import { useEffect, useState } from "react";

import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogActions, DialogContent, Grid } from "@mui/material";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import * as yup from "yup";

import SkeletonInput from "@skeletons/SkeletonInput";
import SkeletonJobDetailForm from "@skeletons/SkeletonJobDetailForm";

import AutoCompleteTextField from "@components/AutoCompleteTextField";
import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import DatePicker from "@components/DatePicker";
import Dialog from "@components/Dialog";
import NonTypeableSelect from "@components/NonTypeableSelect";
import TextField from "@components/TextField";
import TypeableSelect from "@components/TypeableSelect";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import KeyLabel from "@interfaces/components/KeyLabel";
import UserJobExperienceDetail from "@interfaces/database/UserJobExperienceDetail";

import {
  FREE_TEXT_FIELD_MAX_LENGTH,
  JOB_CONTRACT_TYPE,
  JOB_CONTRACT_TYPE_T_LABELS,
  JOB_DETAILS_CURRENT_JOB_CHECKBOX_VALUES,
  LOCALE,
  LOCALE_SHORT,
  MAX_WORK_EXPERIENCES
} from "@utils/config";
import { functions } from "@utils/firebase";
import { getCityList } from "@utils/keyLabelHandlers/location";
import { getDropdownCountryList } from "@utils/location";
import { prepareMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface JobDetailFormDialog {
  companyName?: string;
  jobTitle?: string;
  contractType?: string;
  country?: KeyLabel;
  city?: KeyLabel;
  responsibilities?: string;
  isCurrentJob?: Array<number>;
  startDate?: Date | string;
  endDate?: Date | string;
}

interface JobDetailFormData {
  dialogCompanyName?: string;
  dialogJobTitle?: string;
  dialogContractType?:
    | string
    | typeof JOB_CONTRACT_TYPE[keyof typeof JOB_CONTRACT_TYPE];
  dialogCountry?: KeyLabel;
  dialogCity?: KeyLabel;
  dialogResponsibilities?: string;
  dialogIsCurrentJob?: Array<number>;
  dialogStartDate?: Date | string;
  dialogEndDate?: Date | string;
}

interface JobDetailFormDialogProps {
  initialValues?: JobDetailFormDialog;
  formLanguage?: typeof LOCALE_SHORT[keyof typeof LOCALE_SHORT];
  isDialogOpen: boolean;
  setIsDialogOpen: (isDialogOpen: boolean) => void;
}

const JobDetailFormDialog = ({
  initialValues,
  formLanguage = LOCALE_SHORT.JA,
  isDialogOpen,
  setIsDialogOpen
}: JobDetailFormDialogProps) => {
  const userData = useUserProfile();
  const navigate = useNavigate();
  const toast = useToast();
  const { item_id } = useParams();
  const itemId = Number(item_id);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [translateText, isTranslateTextLoading, translateTextError] =
    useHttpsCallable(functions, "languageTranslator");

  // Fetch the job detail data based on valid itemId
  const userJobDetails = userData.value?.cv?.job_experience ?? [];
  const jobDetail = itemId >= 0 ? userJobDetails?.[itemId] : undefined;
  const isEnAndJaResponsibilityAvailable = !!(
    jobDetail?.responsibilities?.en && jobDetail?.responsibilities?.ja
  );

  const schema = yup.object({
    dialogCompanyName: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_resumes_job_detail_form_label_company_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_resumes_job_detail_form_label_company_name")
        })
      ),
    dialogJobTitle: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_job_title"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_job_title")
        })
      ),
    dialogContractType: yup.string().required(
      intl.get("t_error_required", {
        field: intl.get("t_resumes_job_detail_form_label_contract_type")
      })
    ),
    dialogCountry: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .test(
        "country",
        intl.get("t_error_required", {
          field: intl.get("t_general_country")
        }),
        (value) => (value?.key && value?.label ? true : false)
      )
      .nullable(),
    dialogCity: yup
      .object()
      .shape({
        key: yup.string().trim(),
        label: yup.string().trim()
      })
      .test(
        "label",
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_city"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        }),
        (value) => {
          return value && value.label
            ? value.label.length <= FREE_TEXT_FIELD_MAX_LENGTH
            : true;
        }
      )
      .nullable(),
    dialogResponsibilities: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_resumes_job_detail_form_label_responsibilities")
        })
      )
      .max(
        5000,
        intl.get("t_error_max_limit", {
          field: intl.get("t_resumes_job_detail_form_label_responsibilities"),
          maxLimit: 5000
        })
      ),
    dialogIsCurrentJob: yup.array().test(
      "dialogIsCurrentJob",
      intl.get("t_error_invalid", {
        field: intl.get("t_resumes_job_detail_form_is_current_job")
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (value: any) => {
        if (
          value?.length === 0 ||
          value?.includes(
            JOB_DETAILS_CURRENT_JOB_CHECKBOX_VALUES.IS_CURRENT_JOB_CHECKED
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    ),
    dialogStartDate: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_resumes_job_detail_form_label_start_date")
        })
      )
      .typeError(
        intl.get("t_error_invalid", {
          field: intl.get("t_resumes_job_detail_form_label_start_date")
        })
      ),
    dialogEndDate: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .min(
        yup.ref("dialogStartDate"),
        intl.get("t_error_minimum_date", {
          endDate: intl.get("t_resumes_job_detail_form_label_end_date"),
          startDate: intl.get("t_resumes_job_detail_form_label_start_date")
        })
      )
      .when("dialogIsCurrentJob", {
        is: (dialogIsCurrentJob: Array<number>) =>
          dialogIsCurrentJob?.includes(1),
        then: (schema) => {
          return schema.notRequired();
        },
        otherwise: (schema) => {
          return schema.required(
            intl.get("t_error_required", {
              field: intl.get("t_resumes_job_detail_form_label_end_date")
            })
          );
        }
      })
      .typeError(
        intl.get("t_error_invalid", {
          field: intl.get("t_resumes_job_detail_form_label_end_date")
        })
      )
  });

  const formInitValues = {
    dialogCompanyName: isEnAndJaResponsibilityAvailable
      ? formLanguage === LOCALE_SHORT.JA
        ? jobDetail?.company_name.en ?? ""
        : jobDetail?.company_name.ja ?? ""
      : initialValues?.companyName ?? "",
    dialogJobTitle: isEnAndJaResponsibilityAvailable
      ? formLanguage === LOCALE_SHORT.JA
        ? jobDetail?.job_title.en ?? ""
        : jobDetail?.job_title.ja ?? ""
      : initialValues?.jobTitle ?? "",
    dialogContractType: initialValues?.contractType,
    dialogCountry: initialValues?.country,
    dialogCity: initialValues?.city,
    dialogIsCurrentJob: initialValues?.isCurrentJob,
    dialogStartDate: initialValues?.startDate,
    dialogEndDate: initialValues?.endDate,
    dialogResponsibilities: isEnAndJaResponsibilityAvailable
      ? formLanguage === LOCALE_SHORT.JA
        ? jobDetail?.responsibilities?.en ?? ""
        : jobDetail?.responsibilities?.ja ?? ""
      : initialValues?.companyName ?? ""
  };

  const methods = useForm({
    defaultValues: formInitValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, setValue, reset } = methods;

  const selectedCountryIsoCode = useWatch({
    control,
    name: "dialogCity.key"
  });

  const CONTRACT_TYPE_OPTIONS = useOptions(
    JOB_CONTRACT_TYPE,
    JOB_CONTRACT_TYPE_T_LABELS
  );
  const COUNTRY_OPTIONS = getDropdownCountryList();

  useEffect(() => {
    reset(formInitValues);
    (async () => {
      const { responsibilities } = initialValues || {};
      if (!isEnAndJaResponsibilityAvailable && isDialogOpen) {
        try {
          const res = await translateText({
            textToTranslate: responsibilities,
            targetLangCode:
              formLanguage === LOCALE_SHORT.JA ? LOCALE.EN : LOCALE_SHORT.JA,
            sourceLangCode: formLanguage
          });
          setValue("dialogResponsibilities", (res?.data as string) ?? "");
        } catch (error) {
          toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
        }
      }
    })();
  }, [isDialogOpen]);

  useEffect(() => {
    if (translateTextError) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  }, [translateTextError]);

  const handleJobExperienceDetailsSavedSuccess = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_success_resume_saved"), "success");
  };

  const handleJobExperienceDetailsSavedFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleAdd = async (formData: JobDetailFormData) => {
    setIsDisabled(true);
    const {
      dialogCompanyName,
      dialogJobTitle,
      dialogResponsibilities,
      dialogContractType,
      dialogCountry,
      dialogCity,
      dialogStartDate,
      dialogEndDate
    } = formData || {};

    const jobDetail: UserJobExperienceDetail = {
      company_name: {
        en:
          formLanguage === LOCALE_SHORT.JA
            ? dialogCompanyName
            : initialValues?.companyName,
        ja:
          formLanguage === LOCALE_SHORT.JA
            ? initialValues?.companyName
            : dialogCompanyName
      },
      job_title: {
        en:
          formLanguage === LOCALE_SHORT.JA
            ? dialogJobTitle
            : initialValues?.jobTitle,
        ja:
          formLanguage === LOCALE_SHORT.JA
            ? initialValues?.jobTitle
            : dialogJobTitle
      },
      contract_type:
        dialogContractType as typeof JOB_CONTRACT_TYPE[keyof typeof JOB_CONTRACT_TYPE],
      location: {
        country: dialogCountry?.key ?? "",
        city: dialogCity?.key ?? ""
      },
      timeline: {
        start: Timestamp.fromDate(dialogStartDate as Date)
      },
      responsibilities: {
        en:
          formLanguage === LOCALE_SHORT.JA
            ? dialogResponsibilities
            : initialValues?.responsibilities,
        ja:
          formLanguage === LOCALE_SHORT.JA
            ? initialValues?.responsibilities
            : dialogResponsibilities
      }
    };

    if (dialogEndDate) {
      jobDetail.timeline.end = Timestamp.fromDate(dialogEndDate as Date);
    }

    try {
      if (userData.setValue && userData.value) {
        if (!userData.value?.cv) {
          userData.value.cv = { updated_at: Timestamp.now() };
        }

        if (!userData.value?.cv?.job_experience) {
          userData.value.cv.job_experience = [];
        }

        if (userData.value.cv.job_experience.length >= MAX_WORK_EXPERIENCES) {
          toast.kampai(
            intl.get("t_toast_error_limit_exceed", {
              fieldName: intl.get("t_resumes_job_detail_title"),
              maxLimit: MAX_WORK_EXPERIENCES
            }),
            "error"
          );

          return;
        }

        await userData.setValue(
          {
            ...userData.value,
            cv: {
              ...userData.value?.cv,
              job_experience: [...userData.value.cv.job_experience, jobDetail]
            },
            updated_at: Timestamp.now()
          },
          handleJobExperienceDetailsSavedSuccess,
          handleJobExperienceDetailsSavedFail
        );
        navigate(
          `/${translate.getCurrentLocale()}/profile/resume/${
            formLanguage === LOCALE_SHORT.JA ? "ja-cv" : "en-cv"
          }`
        );
      }
    } catch (e) {
      handleJobExperienceDetailsSavedFail();
    }
  };

  const handleEdit = async (formData: JobDetailFormData) => {
    setIsDisabled(true);
    const {
      dialogCompanyName,
      dialogJobTitle,
      dialogResponsibilities,
      dialogContractType,
      dialogCountry,
      dialogCity,
      dialogStartDate,
      dialogEndDate
    } = formData || {};

    try {
      if (
        item_id &&
        userData.setValue &&
        userData.value &&
        userData.value?.cv?.job_experience
      ) {
        const jobDetail: UserJobExperienceDetail = {
          company_name: {
            en:
              formLanguage === LOCALE_SHORT.JA
                ? dialogCompanyName
                : initialValues?.companyName,
            ja:
              formLanguage === LOCALE_SHORT.JA
                ? initialValues?.companyName
                : dialogCompanyName
          },
          job_title: {
            en:
              formLanguage === LOCALE_SHORT.JA
                ? dialogJobTitle
                : initialValues?.jobTitle,
            ja:
              formLanguage === LOCALE_SHORT.JA
                ? initialValues?.jobTitle
                : dialogJobTitle
          },
          contract_type:
            dialogContractType as typeof JOB_CONTRACT_TYPE[keyof typeof JOB_CONTRACT_TYPE],
          location: {
            country: dialogCountry?.key ?? "",
            city: dialogCity?.key ?? ""
          },
          timeline: {
            start: Timestamp.fromDate(dialogStartDate as Date)
          },
          responsibilities: {
            en:
              formLanguage === LOCALE_SHORT.JA
                ? dialogResponsibilities
                : initialValues?.responsibilities,
            ja:
              formLanguage === LOCALE_SHORT.JA
                ? initialValues?.responsibilities
                : dialogResponsibilities
          }
        };

        if (dialogEndDate) {
          jobDetail.timeline.end = Timestamp.fromDate(dialogEndDate as Date);
        }

        userJobDetails[itemId] = jobDetail;

        await userData.setValue(
          {
            ...userData.value,
            cv: {
              ...userData.value?.cv,
              job_experience: userJobDetails
            },
            updated_at: Timestamp.now()
          },
          handleJobExperienceDetailsSavedSuccess,
          handleJobExperienceDetailsSavedFail
        );
        navigate(
          `/${translate.getCurrentLocale()}/profile/resume/${
            formLanguage === LOCALE_SHORT.JA ? "ja-cv" : "en-cv"
          }`
        );
      }
    } catch (e) {
      handleJobExperienceDetailsSavedFail();
    }
  };

  const handleAddSkip = async () => {
    setIsDisabled(true);
    const newJobDetail: UserJobExperienceDetail = {
      company_name: prepareMultiLingual(
        initialValues?.companyName ?? "",
        formLanguage === LOCALE_SHORT.JA ? LOCALE.JA : LOCALE.EN
      ),
      job_title: prepareMultiLingual(
        initialValues?.jobTitle ?? "",
        formLanguage === LOCALE_SHORT.JA ? LOCALE.JA : LOCALE.EN
      ),
      contract_type:
        initialValues?.contractType as typeof JOB_CONTRACT_TYPE[keyof typeof JOB_CONTRACT_TYPE],
      location: {
        country: initialValues?.country?.key ?? "",
        city: initialValues?.city?.key ?? ""
      },
      timeline: {
        start: Timestamp.fromDate(new Date(initialValues?.startDate as Date))
      },
      responsibilities: prepareMultiLingual(
        initialValues?.responsibilities ?? "",
        formLanguage === LOCALE_SHORT.JA ? LOCALE.JA : LOCALE.EN
      )
    };

    if (initialValues?.endDate) {
      newJobDetail.timeline.end = Timestamp.fromDate(
        new Date(initialValues.endDate as Date)
      );
    }

    try {
      if (userData.setValue && userData.value) {
        if (!userData.value?.cv) {
          userData.value.cv = { updated_at: Timestamp.now() };
        }

        if (!userData.value?.cv?.job_experience) {
          userData.value.cv.job_experience = [];
        }

        if (userData.value.cv.job_experience.length >= MAX_WORK_EXPERIENCES) {
          toast.kampai(
            intl.get("t_toast_error_limit_exceed", {
              fieldName: intl.get("t_resumes_job_detail_title"),
              maxLimit: MAX_WORK_EXPERIENCES
            }),
            "error"
          );

          return;
        }

        await userData.setValue(
          {
            ...userData.value,
            cv: {
              ...userData.value?.cv,
              job_experience: [
                ...userData.value.cv.job_experience,
                newJobDetail
              ]
            },
            updated_at: Timestamp.now()
          },
          handleJobExperienceDetailsSavedSuccess,
          handleJobExperienceDetailsSavedFail
        );
        navigate(
          `/${translate.getCurrentLocale()}/profile/resume/${
            formLanguage === LOCALE_SHORT.JA ? "ja-cv" : "en-cv"
          }`
        );
      }
    } catch (e) {
      handleJobExperienceDetailsSavedFail();
    }
  };

  const handleEditSkip = async () => {
    setIsDisabled(true);
    try {
      if (
        item_id &&
        userData.setValue &&
        userData.value &&
        userData.value?.cv?.job_experience
      ) {
        const updatedJobDetail: UserJobExperienceDetail = {
          company_name: prepareMultiLingual(
            initialValues?.companyName ?? "",
            formLanguage === LOCALE_SHORT.JA ? LOCALE.JA : LOCALE.EN,
            jobDetail?.company_name
          ),
          job_title: prepareMultiLingual(
            initialValues?.jobTitle ?? "",
            formLanguage === LOCALE_SHORT.JA ? LOCALE.JA : LOCALE.EN,
            jobDetail?.job_title
          ),
          contract_type:
            initialValues?.contractType as typeof JOB_CONTRACT_TYPE[keyof typeof JOB_CONTRACT_TYPE],
          location: {
            country: initialValues?.country?.key ?? "",
            city: initialValues?.city?.key ?? ""
          },
          timeline: {
            start: Timestamp.fromDate(
              new Date(initialValues?.startDate as Date)
            )
          },
          responsibilities: prepareMultiLingual(
            initialValues?.responsibilities ?? "",
            formLanguage === LOCALE_SHORT.JA ? LOCALE.JA : LOCALE.EN,
            jobDetail?.responsibilities
          )
        };

        if (initialValues?.endDate) {
          updatedJobDetail.timeline.end = Timestamp.fromDate(
            new Date(initialValues.endDate as Date)
          );
        }

        userJobDetails[itemId] = updatedJobDetail;

        await userData.setValue(
          {
            ...userData.value,
            cv: {
              ...userData.value?.cv,
              job_experience: userJobDetails
            },
            updated_at: Timestamp.now()
          },
          handleJobExperienceDetailsSavedSuccess,
          handleJobExperienceDetailsSavedFail
        );
        navigate(
          `/${translate.getCurrentLocale()}/profile/resume/${
            formLanguage === LOCALE_SHORT.JA ? "ja-cv" : "en-cv"
          }`
        );
      }
    } catch (e) {
      handleJobExperienceDetailsSavedFail();
    }
  };

  if (userData?.loading) {
    return <SkeletonJobDetailForm />;
  }

  return (
    <Dialog
      title={intl.get(
        formLanguage === LOCALE_SHORT.JA
          ? "t_resumes_job_detail_form_auto_translation_dialog_title_ja_cv"
          : "t_resumes_job_detail_form_auto_translation_dialog_title_en_cv"
      )}
      isStopEventPropagation
      isDefaultOpen={isDialogOpen}
      initiator=""
      onClose={() => setIsDialogOpen(false)}>
      {() => (
        <>
          <Box
            noValidate
            component="form"
            id="job-detail-dialog-form"
            onSubmit={handleSubmit(item_id ? handleEdit : handleAdd)}>
            <DialogContent>
              <Typography variant="body1" color="text.secondary" mb={3}>
                {intl.get(
                  formLanguage === LOCALE_SHORT.JA
                    ? "t_resumes_job_detail_form_auto_translation_dialog_subtitle_ja_cv"
                    : "t_resumes_job_detail_form_auto_translation_dialog_subtitle_en_cv"
                )}
              </Typography>
              <Grid container columnSpacing={3}>
                <Grid item xs={12}>
                  <TextField
                    name="dialogCompanyName"
                    label={intl.get(
                      "t_resumes_job_detail_form_label_company_name"
                    )}
                    placeholder={intl.get(
                      "t_resumes_job_detail_form_label_company_name"
                    )}
                    control={control}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    name="dialogJobTitle"
                    label={intl.get("t_general_job_title")}
                    placeholder={intl.get("t_general_job_title")}
                    control={control}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <NonTypeableSelect
                    disabled
                    name="dialogContractType"
                    label={intl.get(
                      "t_resumes_job_detail_form_label_contract_type"
                    )}
                    placeholder={intl.get(
                      "t_resumes_job_detail_form_label_contract_type"
                    )}
                    control={control}
                    setValue={setValue}
                    options={CONTRACT_TYPE_OPTIONS}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TypeableSelect
                    disabled
                    name="dialogCountry"
                    label={intl.get("t_general_country")}
                    placeholder={intl.get(
                      "t_resumes_job_detail_form_label_country_placeholder"
                    )}
                    control={control}
                    options={COUNTRY_OPTIONS}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <AutoCompleteTextField
                    name="dialogCity"
                    label={intl.get("t_general_city")}
                    placeholder={intl.get(
                      "t_resumes_job_detail_form_label_city_placeholder"
                    )}
                    control={control}
                    disabled
                    setValue={setValue}
                    getOptions={(subText) => {
                      return getCityList(subText, selectedCountryIsoCode);
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Checkbox
                    disabled
                    size="medium"
                    name="dialogIsCurrentJob"
                    control={control}
                    setValue={setValue}
                    options={[
                      {
                        label: intl.get(
                          "t_resumes_job_detail_form_label_is_current_job_check"
                        ),
                        value:
                          JOB_DETAILS_CURRENT_JOB_CHECKBOX_VALUES.IS_CURRENT_JOB_CHECKED
                      }
                    ]}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <DatePicker
                    disabled
                    name="dialogStartDate"
                    label={intl.get(
                      "t_resumes_job_detail_form_label_start_date"
                    )}
                    control={control}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <DatePicker
                    name="dialogEndDate"
                    label={intl.get("t_resumes_job_detail_form_label_end_date")}
                    control={control}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  {isTranslateTextLoading ? (
                    <SkeletonInput multiLine />
                  ) : (
                    <TextField
                      name="dialogResponsibilities"
                      label={intl.get(
                        "t_resumes_job_detail_form_label_responsibilities"
                      )}
                      placeholder={intl.get(
                        "t_resumes_job_detail_placeholder_responsibilities"
                      )}
                      required
                      control={control}
                      multiline
                      maxLength={5000}
                      rows={3}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
          <DialogActions>
            <Button
              handleClick={item_id ? handleEditSkip : handleAddSkip}
              variant="outlined"
              disabled={isTranslateTextLoading || isDisabled}>
              {intl.get("t_general_skip")}
            </Button>
            <Button
              disabled={isTranslateTextLoading || isDisabled}
              type="submit"
              loading={isDisabled}
              formId="job-detail-dialog-form">
              {intl.get("t_general_save")}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default JobDetailFormDialog;

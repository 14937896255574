import { Fragment, useEffect, useState } from "react";

import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddCircle as AddCircleIcon,
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon
} from "@mui/icons-material";
import { Box, Collapse, Grid, IconButton, Stack, styled } from "@mui/material";
import deepEqual from "deep-equal";
import * as yup from "yup";

import SkeletonLanguageRequirementForm from "@skeletons/EmployersPostJob/SkeletonLanguageRequirementForm";
import SkeletonButton from "@skeletons/SkeletonButton";

import Button from "@components/Button";
import Icon from "@components/Icon";
import NonTypeableSelect from "@components/NonTypeableSelect";
import Paper from "@components/Paper";
import TypeableSelect from "@components/TypeableSelect";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import KeyLabel from "@interfaces/components/KeyLabel";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import Language from "@interfaces/database/Language";

import {
  EN_ALPHA3_CODE,
  JA_ALPHA3_CODE,
  LANGUAGE_PROFICIENCY,
  LANGUAGE_PROFICIENCY_T_LABELS,
  MAX_LANGUAGES,
  TOTAL_JOB_POSTING_STEPS
} from "@utils/config";
import { getDropdownLanguageList, getLanguageName } from "@utils/language";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

const StyledIconButton = styled(IconButton)(() => ({
  float: "right",
  marginTop: "1rem"
}));

interface LanguageData {
  name?: KeyLabel;
  proficiency?: typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY];
}

interface LanguageRequirementFormData {
  jaLanguage?: LanguageData;
  enLanguage?: LanguageData;
  language?: Array<LanguageData>;
}

const LanguageRequirement = () => {
  const { job_id: jobId, data_locale: dataLocale } = useParams();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    jobData,
    handleSetJobData
  }: {
    jobData: JobProfile;
    handleSetJobData: (
      jobId: JobID,
      jobData: JobProfile,
      handleJobUpdateSuccess: (jobId: string) => void,
      handleJobUpdateFail: () => void
    ) => void;
  } = useOutletContext();
  const companyDetails = useCompanyDetails();
  const toast = useToast();

  const isCompanyDetailsLoading = companyDetails.loading;
  const languageRequirement = jobData?.language_requirement ?? [];
  const addedLanguages =
    languageRequirement?.slice(2).map((singleLanguage) => {
      return {
        name: {
          label: getLanguageName(singleLanguage?.name) ?? "",
          key: singleLanguage?.name ?? ""
        },
        proficiency: singleLanguage?.proficiency
      };
    }) ?? []; // remove first two language(japanese and english)

  const commonLanguageSchema = {
    name: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .test(
        "name",
        intl.get("t_error_required", {
          field: intl.get("t_job_post_language_information_step_language")
        }),
        (value) => (value?.key && value?.label ? true : false)
      )
      .nullable(),
    proficiency: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_job_post_language_information_step_proficiency")
        })
      )
      .nullable()
  };

  // validation schema
  const schema = yup.object({
    jaLanguage: yup.object().shape(commonLanguageSchema),
    enLanguage: yup.object().shape(commonLanguageSchema),
    language: yup.array().of(yup.object().shape(commonLanguageSchema))
  });

  const formInitValues = {
    jaLanguage: {
      name: {
        key:
          languageRequirement.length > 0
            ? languageRequirement?.[0]?.name
            : JA_ALPHA3_CODE,
        label:
          getLanguageName(
            languageRequirement.length > 0
              ? languageRequirement?.[0]?.name
              : JA_ALPHA3_CODE
          ) ?? ""
      },
      proficiency:
        languageRequirement.length > 0
          ? languageRequirement?.[0]?.proficiency
          : LANGUAGE_PROFICIENCY.NONE
    },
    enLanguage: {
      name: {
        key:
          languageRequirement.length > 1
            ? languageRequirement?.[1]?.name
            : EN_ALPHA3_CODE,
        label:
          getLanguageName(
            languageRequirement.length > 0
              ? languageRequirement?.[1]?.name
              : EN_ALPHA3_CODE
          ) ?? ""
      },
      proficiency:
        languageRequirement.length > 1
          ? languageRequirement?.[1]?.proficiency
          : LANGUAGE_PROFICIENCY.NONE
    },
    language: addedLanguages
  };

  const methods = useForm({
    defaultValues: formInitValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, setValue, reset } = methods;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const languageFieldArr = useFieldArray<any>({
    control,
    name: "language"
  });

  useEffect(() => {
    reset(formInitValues);
  }, [jobData?.language_requirement]);

  const LANGUAGE_PROFICIENCY_OPTIONS = useOptions(
    LANGUAGE_PROFICIENCY,
    LANGUAGE_PROFICIENCY_T_LABELS
  );
  const ALL_LANGUAGES = getDropdownLanguageList();

  const handleJobUpdateSuccess = (updatedJobId: string) => {
    setIsDisabled(false);
    navigate(
      `/${translate.getCurrentLocale()}/employers/jobs/${updatedJobId}/${dataLocale}/additional-information`
    );
  };

  const handleJobUpdateFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleOnSubmit = async (formData: LanguageRequirementFormData) => {
    const isFormValueChanged = !deepEqual(formData, formInitValues);
    try {
      setIsDisabled(true);
      const {
        enLanguage,
        jaLanguage,
        language: addedLanguages = []
      } = formData || {};
      if (companyDetails?.value && !companyDetails?.value?.jobs) {
        companyDetails.value.jobs = [];
      }

      // removed empty language object
      const userAddedLanguages: Array<Language> = addedLanguages
        ?.filter(
          (singleLanguage: LanguageData) =>
            singleLanguage?.name?.key?.toUpperCase() &&
            singleLanguage?.proficiency
        )
        .map((singleLanguage: LanguageData) => {
          return {
            name: singleLanguage?.name?.key?.toUpperCase() ?? "",
            proficiency:
              singleLanguage?.proficiency ?? LANGUAGE_PROFICIENCY.NONE
          };
        });

      if (
        jobId &&
        companyDetails?.value?.jobs &&
        (isFormValueChanged || !jobData.language_requirement) // if not any language found then update default language(en,ja)
      ) {
        handleSetJobData(
          jobId,
          {
            ...jobData,
            language_requirement: [
              {
                name: JA_ALPHA3_CODE,
                proficiency:
                  jaLanguage?.proficiency as typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY]
              },
              {
                name: EN_ALPHA3_CODE,
                proficiency:
                  enLanguage?.proficiency as typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY]
              },
              ...(userAddedLanguages as Array<Language>)
            ],
            updated_at: Timestamp.now()
          },
          handleJobUpdateSuccess,
          handleJobUpdateFail
        );
      } else if (jobId) {
        handleJobUpdateSuccess(jobId);
      }
    } catch (e) {
      handleJobUpdateFail();
    }
  };

  const handleRemoveLanguageField = (idx: number) => {
    languageFieldArr.remove(idx);
  };

  return (
    <>
      <Box noValidate component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Paper>
          <Stack direction={{ xs: "column", md: "row" }} gap={{ md: 10 }}>
            <Box width={{ xs: "100%", md: "40%" }}>
              <Typography variant="subtitle2" mb={3} color="secondary.main">
                {intl.get("t_general_step", { stepNumber: 5 })}/
                {TOTAL_JOB_POSTING_STEPS}
              </Typography>
              <br />
              <Typography variant="h4" mb={2.5}>
                {intl.get("t_job_post_language_information_step_label")}
              </Typography>
            </Box>
            <Stack width={{ xs: "100%", md: "60%" }}>
              {isCompanyDetailsLoading ? (
                <SkeletonLanguageRequirementForm />
              ) : (
                <>
                  {/* form for japanese language start */}
                  <Grid container spacing={{ xs: 0, md: 2 }}>
                    <Grid item xs={12} md={5.5}>
                      <TypeableSelect
                        data-testid="language_information_japanese_language_select"
                        control={control}
                        name="jaLanguage.name"
                        label={intl.get(
                          "t_job_post_language_information_step_language"
                        )}
                        placeholder={intl.get(
                          "t_job_post_language_information_step_language"
                        )}
                        readOnly
                        options={[]}
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item xs={12} md={5.5}>
                      <NonTypeableSelect
                        data-testid="language_information_japanese_proficiency_select"
                        disabled={isDisabled}
                        setValue={setValue}
                        control={control}
                        name="jaLanguage.proficiency"
                        label={intl.get(
                          "t_job_post_language_information_step_proficiency"
                        )}
                        placeholder={intl.get(
                          "t_job_post_language_information_step_proficiency_placeholder"
                        )}
                        required
                        options={LANGUAGE_PROFICIENCY_OPTIONS}
                      />
                    </Grid>
                  </Grid>
                  {/* form for japanese language end */}

                  {/* form for english language start */}
                  <Grid container spacing={{ xs: 0, md: 2 }}>
                    <Grid item xs={12} md={5.5}>
                      <TypeableSelect
                        data-testid="language_information_english_language_select"
                        control={control}
                        name="enLanguage.name"
                        label={intl.get(
                          "t_job_post_language_information_step_language"
                        )}
                        placeholder={intl.get(
                          "t_job_post_language_information_step_language"
                        )}
                        required
                        readOnly
                        options={[]}
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item xs={12} md={5.5}>
                      <NonTypeableSelect
                        data-testid="language_information_english_proficiency_select"
                        disabled={isDisabled}
                        setValue={setValue}
                        control={control}
                        name="enLanguage.proficiency"
                        label={intl.get(
                          "t_job_post_language_information_step_proficiency"
                        )}
                        placeholder={intl.get(
                          "t_job_post_language_information_step_proficiency_placeholder"
                        )}
                        required
                        options={LANGUAGE_PROFICIENCY_OPTIONS}
                      />
                    </Grid>
                  </Grid>
                  {/* form for english language end */}

                  {/* form for other language start */}
                  <Grid item xs={12} md={5.5}>
                    <TransitionGroup>
                      {languageFieldArr.fields.map(({ id }, index) => {
                        return (
                          <Collapse key={id}>
                            <Grid container columnSpacing={2}>
                              <Grid item xs={12} md={5.5}>
                                <TypeableSelect
                                  data-testid="language_information_other_language_select"
                                  disabled={isDisabled}
                                  control={control}
                                  name={`language.${index}.name`}
                                  label={intl.get(
                                    "t_job_post_language_information_step_language"
                                  )}
                                  placeholder={intl.get(
                                    "t_job_post_language_information_step_language"
                                  )}
                                  required
                                  options={ALL_LANGUAGES}
                                />
                              </Grid>
                              <Grid item xs={12} md={5.5}>
                                <NonTypeableSelect
                                  data-testid="language_information_other_proficiency_select"
                                  disabled={isDisabled}
                                  setValue={setValue}
                                  control={control}
                                  name={`language.${index}.proficiency`}
                                  label={intl.get(
                                    "t_job_post_language_information_step_proficiency"
                                  )}
                                  placeholder={intl.get(
                                    "t_job_post_language_information_step_proficiency_placeholder"
                                  )}
                                  required
                                  options={LANGUAGE_PROFICIENCY_OPTIONS}
                                />
                              </Grid>
                              <Grid item xs={12} md={1} mt={{ xs: 0, md: 3 }}>
                                <StyledIconButton
                                  onClick={() =>
                                    handleRemoveLanguageField(index)
                                  }>
                                  <Icon type="delete" filled />
                                </StyledIconButton>
                              </Grid>
                            </Grid>
                          </Collapse>
                        );
                      })}
                    </TransitionGroup>
                  </Grid>
                  {/* form for other language end */}
                  <Grid
                    item
                    xs={12}
                    mt={languageFieldArr.fields.length > 0 ? 0 : 1}>
                    <Button
                      data-testid="language_information_add_another_language_button"
                      color="primary"
                      handleClick={() => {
                        // if the max limit is exceeded, set a toast notification
                        if (
                          languageFieldArr.fields.length >=
                          MAX_LANGUAGES - 1
                        ) {
                          toast.kampai(
                            intl.get("t_toast_error_limit_exceed", {
                              fieldName: intl.get("t_profile_summary_language"),
                              maxLimit: MAX_LANGUAGES
                            }),
                            "error"
                          );
                          return;
                        }
                        return languageFieldArr.append({
                          name: "",
                          proficiency: ""
                        });
                      }}
                      size="small"
                      startAdornment={<AddCircleIcon />}
                      variant="text">
                      {intl.get("t_general_add_another")}
                    </Button>
                  </Grid>
                </>
              )}
            </Stack>
          </Stack>
        </Paper>
        <br />
        <Stack direction="row" justifyContent="space-between">
          {isCompanyDetailsLoading ? (
            <>
              <SkeletonButton />
              <SkeletonButton />
            </>
          ) : (
            <>
              <Button
                data-testid="language_information_back_button"
                variant="outlined"
                startAdornment={<NavigateBeforeIcon />}
                handleClick={() =>
                  navigate(
                    `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/${dataLocale}/job-overview`
                  )
                }>
                {intl.get("t_general_back")}
              </Button>
              <Button
                data-testid="language_information_next_button"
                loading={isDisabled}
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                endAdornment={<NavigateNextIcon />}>
                {intl.get("t_general_save_and_next")}
              </Button>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default LanguageRequirement;

import { Fragment, useEffect, useState } from "react";

import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddCircle as AddCircleIcon,
  Add as AddIcon,
  Close as CloseIcon,
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon
} from "@mui/icons-material";
import { Box, Collapse, Grid, Stack } from "@mui/material";
import deepEqual from "deep-equal";
import { httpsCallable, HttpsCallableResult } from "firebase/functions";
import { MultiSearchResponse } from "typesense/lib/Typesense/MultiSearch";
import * as yup from "yup";

import { StyledTransitionGroup } from "@pages/DesktopProfileSummaryForm";

import SkeletonSkillRequirementForm from "@skeletons/EmployersPostJob/SkeletonSkillRequirementForm";
import SkeletonKeyword from "@skeletons/SkeletonKeyword";

import AutoCompleteTextField from "@components/AutoCompleteTextField";
import Button from "@components/Button";
import Cursor from "@components/Cursor";
import Keyword from "@components/Keyword";
import NonTypeableSelect from "@components/NonTypeableSelect";
import Paper from "@components/Paper";
import Tag from "@components/Tag";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import KeyLabel from "@interfaces/components/KeyLabel";
import SkillData from "@interfaces/components/SkillData";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import Skill from "@interfaces/database/Skill";
import TypesenseSkill from "@interfaces/database/TypesenseSkill";

import {
  FREE_TEXT_FIELD_MAX_LENGTH,
  MAX_SKILLS,
  RELATED_SKILL_LIST_MAX_SIZE,
  SKILL_FIELD_MAX_LENGTH,
  TOTAL_JOB_POSTING_STEPS,
  TYPESENSE_COLLECTIONS,
  YEARS_OF_EXPERIENCE,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import { functions } from "@utils/firebase";
import { getSkillLabel, getSkillList } from "@utils/keyLabelHandlers/skill";
import { addSkill, isSkillExistsByKey } from "@utils/metaSkills";
import { resolveMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";
import getClient from "@utils/typesense";

interface SkillRequirementForm {
  skills: Array<SkillData>;
}

const emptySkill = {
  name: { key: "", label: "" },
  yearOfExperience: ""
};

const SkillRequirement = () => {
  const { job_id: jobId, data_locale: dataLocale } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [formInitValues, setFormInitValues] = useState<Array<SkillData>>();
  const navigate = useNavigate();
  const {
    jobData,
    handleSetJobData
  }: {
    jobData: JobProfile;
    handleSetJobData: (
      jobId: JobID,
      jobData: JobProfile,
      handleJobUpdateSuccess: (jobId: string) => void,
      handleJobUpdateFail: () => void
    ) => void;
  } = useOutletContext();
  const companyDetails = useCompanyDetails();
  const toast = useToast();
  const currentDataLocale = translate.getLocaleFromShort(dataLocale);
  const [isRecommendedSkillsLoading, setIsRecommendedSkillsLoading] =
    useState<boolean>(false);
  const [recommendedSkillListArr, setRecommendedSkillListArr] = useState<
    Array<KeyLabel>
  >([]);
  const [selectedSkillKey, setSelectedSkillKey] = useState<string>("");

  const isCompanyDetailsLoading = companyDetails?.loading || isLoading;

  // validation schema
  const schema = yup.object({
    skills: yup
      .array()
      .of(
        yup.object().shape({
          name: yup
            .object()
            .shape({
              key: yup.string().trim(),
              label: yup.string().trim()
            })
            .test(
              "label",
              intl.get("t_error_max_limit", {
                field: intl.get("t_general_skill"),
                maxLimit: SKILL_FIELD_MAX_LENGTH
              }),
              (value) => {
                return value && value.label
                  ? value.label.length <= SKILL_FIELD_MAX_LENGTH
                  : true;
              }
            )
            .test(
              "is-required",
              intl.get("t_error_required", {
                field: intl.get("t_general_skill")
              }),
              (
                skillName,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                context: any
              ) => {
                // check minimum 1 value is entered or not
                if (
                  context?.path === "skills[0].name" &&
                  context?.originalValue
                ) {
                  if (
                    !context.originalValue.key ||
                    !context.originalValue.label
                  ) {
                    return false;
                  }
                }

                // check yearOfExperience is entered then skill name is required
                const { yearOfExperience } = context.parent as SkillData;
                if (yearOfExperience) {
                  return !!skillName?.key;
                }
                return true;
              }
            )
            .test(
              "label",
              intl.get("t_error_max_limit", {
                field: intl.get("t_general_skill"),
                maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
              }),
              (value) => {
                return value && value.label
                  ? value.label.length <= FREE_TEXT_FIELD_MAX_LENGTH
                  : true;
              }
            )
            .nullable(),
          yearOfExperience: yup
            .string()
            .nullable()
            .test(
              "is-required",
              intl.get("t_error_required", {
                field: intl.get("t_profile_job_overview_year_of_experience")
              }),
              (
                yearOfExperience,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                context: any
              ) => {
                // check minimum 1 value is entered or not
                if (
                  context?.path === "skills[0].yearOfExperience" &&
                  !context.originalValue
                ) {
                  return false;
                }

                // check skill name is entered then yearOfExperience is required
                const { name } = context.parent as SkillData;
                if (name?.key) {
                  return !!yearOfExperience && yearOfExperience.length > 0;
                }
                return true;
              }
            )
        })
      )
      .test("is-duplicate", "", (skills, { createError }) => {
        if (skills) {
          const skillLabels = new Set<string>();

          for (const singleSkill of skills) {
            // skip if the skill is not entered
            if (!singleSkill.name || !singleSkill.name.label) {
              continue;
            }

            if (skillLabels.has(singleSkill.name.label.toLowerCase())) {
              // check if duplicate skill is entered and return error
              return createError({
                path: `skills[${skills.indexOf(singleSkill)}].name`,
                message: intl.get("t_error_duplicate_value", {
                  value: singleSkill.name.label
                })
              });
            }

            skillLabels.add(singleSkill.name.label.toLowerCase());
          }
          return true;
        } else {
          return true;
        }
      })
  });

  const methods = useForm({
    defaultValues: {
      skills: [emptySkill]
    },
    resolver: yupResolver(schema)
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    getValues,
    reset,
    setFocus
  } = methods;

  const skillsFieldArr = useFieldArray({
    control,
    name: "skills"
  });

  useEffect(() => {
    const getInitialSkillValues = async () => {
      setIsLoading(true);
      const initialSkills: Array<SkillData> = [];
      if (jobData?.skills) {
        for (let i = 0; i < jobData?.skills?.length; i++) {
          const singleSkill = jobData?.skills[i];
          initialSkills.push({
            name: {
              key: singleSkill.name,
              label: await getSkillLabel(
                singleSkill?.name,
                translate.getCurrentLocale()
              )
            },
            yearOfExperience: singleSkill?.years_of_experience
          });
        }
      }
      const skillKeys = initialSkills
        .map((singleSkill) => singleSkill?.name?.key ?? "")
        .filter((singleSkillKey) => singleSkillKey !== "");
      if (skillKeys.length > 0) {
        updateRecommendedSkills(skillKeys);
      }
      setFormInitValues(initialSkills);
      reset({ skills: initialSkills });
      // append empty object to make it initially empty
      skillsFieldArr.append(emptySkill); // empty the name and yearOfExperience, when user try to edit
      setIsLoading(false);
    };
    getInitialSkillValues();
  }, [jobData?.skills]);

  const YEARS_OF_EXPERIENCE_OPTIONS = useOptions(
    YEARS_OF_EXPERIENCE,
    YEARS_OF_EXPERIENCE_T_LABELS
  );

  const handleJobUpdateSuccess = (updatedJobId: string) => {
    setIsDisabled(false);
    navigate(
      `/${translate.getCurrentLocale()}/employers/jobs/${updatedJobId}/${dataLocale}/job-overview`
    );
  };

  const handleJobUpdateFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleOnSubmit = async (formData: SkillRequirementForm) => {
    for (let index = 0; index < formData.skills.length; index++) {
      const singleSkill = formData.skills[index];
      const isSkillAlreadyExistsByKey = await isSkillExistsByKey(
        singleSkill?.name?.key
      );
      if (!isSkillAlreadyExistsByKey) {
        const skillKey = await addSkill(
          singleSkill?.name?.label,
          translate.getCurrentLocale()
        );
        if (skillKey && singleSkill?.name?.key) {
          singleSkill.name.key = skillKey;
        }
      }
    }

    const initialSkills = formInitValues
      ?.map((singleSkillData: SkillData) => {
        return {
          name: singleSkillData?.name?.key,
          years_of_experience: singleSkillData.yearOfExperience
        };
      })
      ?.filter(
        // ignore the empty skills object and return only valid skills
        (singleSkillData: { name: string | undefined }) => singleSkillData?.name
      );

    const selectedSkills = formData?.skills
      ?.map((singleSkillData: SkillData) => {
        return {
          name: singleSkillData?.name?.key,
          years_of_experience: singleSkillData.yearOfExperience
        };
      })
      ?.filter(
        // ignore the empty skills object and return only valid skills
        (singleSkillData: { name: string | undefined }) => singleSkillData?.name
      );

    if (selectedSkills?.length > MAX_SKILLS) {
      toast.kampai(
        intl.get("t_toast_error_limit_exceed", {
          fieldName: intl.get("t_profile_skill_skill"),
          maxLimit: MAX_SKILLS
        }),
        "error"
      );
      return;
    }
    setIsDisabled(true);
    try {
      const isFormValueChanged = !deepEqual(selectedSkills, initialSkills);
      if (companyDetails?.value && !companyDetails?.value?.jobs) {
        companyDetails.value.jobs = [];
      }
      if (jobId && companyDetails?.value?.jobs && isFormValueChanged) {
        handleSetJobData(
          jobId,
          {
            ...jobData,
            skills: selectedSkills as Array<Skill>,
            updated_at: Timestamp.now()
          },
          handleJobUpdateSuccess,
          handleJobUpdateFail
        );
      } else if (jobId) {
        handleJobUpdateSuccess(jobId);
      }
    } catch (e) {
      setIsDisabled(false);
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  };

  const updateRecommendedSkills = async (skillKeys: string | Array<string>) => {
    if (!skillKeys || isRecommendedSkillsLoading) {
      return;
    }
    if (!Array.isArray(skillKeys)) {
      skillKeys = [skillKeys];
    }

    setIsRecommendedSkillsLoading(true);

    const recommendedSkills: Array<KeyLabel> = recommendedSkillListArr;

    for await (const singleSkillKey of skillKeys) {
      const currentLocale = translate.getCurrentLocale();
      const typesenseDocumentId = singleSkillKey
        ? `${singleSkillKey}_${currentLocale}`
        : "";
      const typesenseClient = getClient();
      const selectedSkillKeys = getValues("skills")
        .map((singleSkill: SkillData) =>
          singleSkill.name?.key
            ? `${singleSkill.name?.key}_${currentLocale}`
            : ""
        )
        .filter((singleKey) => singleKey !== "");
      const recommendedSkillsKeys = recommendedSkills
        .map((singleRecommendedSkill) =>
          singleRecommendedSkill.key
            ? `${singleRecommendedSkill.key}_${currentLocale}`
            : ""
        )
        .filter((singleKey) => singleKey !== "");

      const ignoreSkillKeys = [...selectedSkillKeys, ...recommendedSkillsKeys];

      const perPageCount = Math.max(
        RELATED_SKILL_LIST_MAX_SIZE - recommendedSkills.length,
        3
      );

      const relatedTypesenseSkillDocuments =
        (await typesenseClient.multiSearch.perform({
          searches: [
            {
              collection: TYPESENSE_COLLECTIONS.SKILLS,
              q: "*",
              query_by: "",
              vector_query: `embedding:([], id: ${typesenseDocumentId})`,
              per_page: perPageCount,
              hidden_hits: ignoreSkillKeys.join(","),
              filter_by: `locale:=${translate.getCurrentLocale()}`
            }
          ]
        })) as MultiSearchResponse<Array<TypesenseSkill>>;

      const relatedSkillsResults = relatedTypesenseSkillDocuments.results;
      if (
        !relatedSkillsResults ||
        !relatedSkillsResults[0] ||
        !relatedSkillsResults[0].hits
      ) {
        setIsRecommendedSkillsLoading(false);
        return;
      }
      const relatedSkills = relatedSkillsResults[0].hits;

      recommendedSkills.unshift(
        ...relatedSkills.map((singleRelatedSkill) => ({
          key: singleRelatedSkill.document.key,
          label: singleRelatedSkill.document.label
        }))
      );
    }

    const filteredRecommendedSkills = recommendedSkills
      .filter((singleRecommendedSkill) => {
        const isSkillAlreadyExistsByKey = skillKeys.includes(
          singleRecommendedSkill.key
        );
        return !isSkillAlreadyExistsByKey;
      })
      .slice(0, RELATED_SKILL_LIST_MAX_SIZE);

    setRecommendedSkillListArr([...filteredRecommendedSkills]);
    setIsRecommendedSkillsLoading(false);
  };

  useEffect(() => {
    if (jobData) {
      if (
        !jobData.skills ||
        !Array.isArray(jobData.skills) ||
        jobData.skills.length <= 1
      ) {
        const jobTitle = resolveMultiLingual(
          jobData.job_title,
          currentDataLocale
        );
        const jobDescription = resolveMultiLingual(
          jobData.job_description,
          currentDataLocale
        );
        const mustRequirements = resolveMultiLingual(
          jobData.must_requirements,
          currentDataLocale
        );

        const content = `${jobTitle} ${jobDescription} ${mustRequirements}`;
        if (content !== "") {
          (async () => {
            setIsRecommendedSkillsLoading(false);
            const getRecommendedSkillsByContent = httpsCallable(
              functions,
              "getRecommendedSkillsByContent"
            );
            try {
              const recommendedSkillInfo = (await getRecommendedSkillsByContent(
                {
                  content,
                  options: {
                    locale: translate.getLocaleFromShort(dataLocale)
                  }
                }
              )) as HttpsCallableResult<{ related_skills?: Array<KeyLabel> }>;
              const relatedSkills: Array<KeyLabel> =
                recommendedSkillInfo.data?.related_skills ?? [];
              setRecommendedSkillListArr([...relatedSkills]);
              setIsRecommendedSkillsLoading(false);
            } catch (e) {
              setIsRecommendedSkillsLoading(false);
            }
          })();
        }
      }
    }
  }, [jobData]);

  return (
    <Box noValidate component="form" onSubmit={handleSubmit(handleOnSubmit)}>
      <Paper>
        <Stack direction={{ xs: "column", md: "row" }} gap={{ md: 10 }}>
          <Stack width={{ xs: "100%", md: "40%" }}>
            <Typography variant="subtitle2" mb={3} color="secondary.main">
              {intl.get("t_general_step", { stepNumber: 3 })}/
              {TOTAL_JOB_POSTING_STEPS}
            </Typography>
            <Typography variant="h4" mb={2.5}>
              {intl.get("t_job_post_skill_information_step_label")}
            </Typography>
          </Stack>
          <Box width={{ xs: "100%", md: "60%" }}>
            {isCompanyDetailsLoading ? (
              <SkeletonSkillRequirementForm />
            ) : (
              <>
                <Grid container columnSpacing={3} mb={{ xs: 1, md: 0 }}>
                  {skillsFieldArr.fields.map(({ id }, index) => {
                    return (
                      <Fragment key={id}>
                        {skillsFieldArr.fields.length === index + 1 ? (
                          <Fragment key={id}>
                            <Grid item xs={12} md={6}>
                              <AutoCompleteTextField
                                data-testid="skill_information_skill_input"
                                disabled={isDisabled}
                                getOptions={getSkillList}
                                setValue={(_, value, options) => {
                                  setSelectedSkillKey(value?.key ?? "");
                                  setValue(
                                    `skills.${index}.name`,
                                    value,
                                    options
                                  );
                                }}
                                control={control}
                                name={`skills.${index}.name`}
                                label={intl.get(
                                  "t_job_post_skill_information_step_skills"
                                )}
                                placeholder={intl.get(
                                  "t_job_post_skill_information_step_skills"
                                )}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <NonTypeableSelect
                                data-testid="skill_information_year_of_experience_select"
                                disabled={isDisabled}
                                setValue={setValue}
                                control={control}
                                name={`skills.${index}.yearOfExperience`}
                                label={intl.get(
                                  "t_job_post_skill_information_step_minimum_year_of_experience"
                                )}
                                placeholder={intl.get(
                                  "t_job_post_skill_information_step_minimum_year_of_experience"
                                )}
                                required
                                options={YEARS_OF_EXPERIENCE_OPTIONS}
                              />
                            </Grid>
                          </Fragment>
                        ) : (
                          false
                        )}
                      </Fragment>
                    );
                  })}
                </Grid>
                <Button
                  data-testid="skill_information_add_skill_button"
                  color="primary"
                  disabled={isDisabled}
                  handleClick={async () => {
                    // if the max limit is exceeded, set a toast notification
                    if (skillsFieldArr.fields.length > MAX_SKILLS) {
                      toast.kampai(
                        intl.get("t_toast_error_limit_exceed", {
                          fieldName: intl.get(
                            "t_job_post_skill_information_step_skills"
                          ),
                          maxLimit: MAX_SKILLS
                        }),
                        "error"
                      );
                      return;
                    }
                    const isValid = await trigger("skills");
                    // if skill is not selected then return
                    if (
                      !watch(`skills.${skillsFieldArr.fields.length - 1}.name`)
                    ) {
                      return;
                    }
                    if (isValid) {
                      const skillKey = getValues(
                        `skills.${skillsFieldArr.fields.length - 1}.name.key`
                      );

                      updateRecommendedSkills(skillKey);
                      return skillsFieldArr.append(emptySkill);
                    }
                  }}
                  size="small"
                  startAdornment={<AddCircleIcon />}
                  variant="text">
                  {intl.get("t_job_post_skill_information_step_add_skill")}
                </Button>

                <Box>
                  <Typography variant="h4" my={2}>
                    {intl.get("t_general_recommended_skills")}
                  </Typography>
                  {isRecommendedSkillsLoading || isLoading ? (
                    <Stack
                      direction="row"
                      gap={1}
                      mt={2}
                      alignItems="center"
                      flexWrap="wrap">
                      {[...Array(10)].map((_, idx) => (
                        <SkeletonKeyword key={idx} />
                      ))}
                    </Stack>
                  ) : recommendedSkillListArr.length == 0 ? (
                    <Box>
                      <Typography variant="subtitle4" color="text.secondary">
                        Please fill job overview or select some skills to get
                        recommendations
                      </Typography>
                    </Box>
                  ) : (
                    <Stack
                      direction="row"
                      gap={1}
                      mt={2}
                      alignItems="center"
                      flexWrap="wrap">
                      {recommendedSkillListArr.map((singleRecommendedSkill) => (
                        <Box
                          key={singleRecommendedSkill.key}
                          onClick={() => {
                            setSelectedSkillKey(singleRecommendedSkill.key);
                            setValue(
                              `skills.${skillsFieldArr.fields.length - 1}.name`,
                              singleRecommendedSkill
                            );
                            setFocus(
                              `skills.${
                                skillsFieldArr.fields.length - 1
                              }.yearOfExperience`
                            );
                          }}>
                          <Keyword
                            isClickable
                            isSelected={
                              singleRecommendedSkill.key === selectedSkillKey
                            }
                            label={singleRecommendedSkill.label}
                            startAdornment={<AddIcon height={24} width={24} />}
                          />
                        </Box>
                      ))}
                    </Stack>
                  )}
                </Box>

                {/* if 1 skill added then show selected skills section */}
                {skillsFieldArr.fields.length > 1 ? (
                  <>
                    <br />
                    <Typography
                      variant="subtitle4"
                      color="text.secondary"
                      mt={2}
                      mb={1.5}>
                      {intl.get(
                        "t_job_post_skill_information_step_selected_skills"
                      )}
                    </Typography>
                    <br />
                    <StyledTransitionGroup>
                      {/* ignore last index object from rendering */}
                      {getValues("skills")
                        .slice(0, skillsFieldArr.fields.length - 1)
                        .map((singleField: SkillData, index: number) => {
                          return (
                            <Collapse key={index}>
                              {singleField?.name?.label &&
                              singleField?.yearOfExperience ? (
                                <Tag
                                  label={`${
                                    singleField?.name?.label
                                  } ${intl.get(
                                    YEARS_OF_EXPERIENCE_T_LABELS[
                                      singleField?.yearOfExperience as keyof typeof YEARS_OF_EXPERIENCE_T_LABELS
                                    ]
                                  )}`}
                                  endAdornment={
                                    <Box
                                      component="span"
                                      onClick={() => {
                                        if (!isDisabled && !isLoading) {
                                          skillsFieldArr.remove(index);
                                        }
                                      }}>
                                      <Cursor>
                                        <CloseIcon fontSize="small" />
                                      </Cursor>
                                    </Box>
                                  }
                                />
                              ) : (
                                false
                              )}
                            </Collapse>
                          );
                        })}
                    </StyledTransitionGroup>
                  </>
                ) : (
                  false
                )}
              </>
            )}
          </Box>
        </Stack>
      </Paper>
      <br />
      <Stack direction="row" justifyContent="space-between">
        <>
          <Button
            variant="outlined"
            startAdornment={<NavigateBeforeIcon />}
            handleClick={() =>
              navigate(
                `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/${dataLocale}/job-information`
              )
            }>
            {intl.get("t_general_back")}
          </Button>
          {isLoading ? (
            <Button
              variant="contained"
              color="primary"
              size="large"
              endAdornment={<NavigateNextIcon />}>
              {intl.get("t_general_save_and_next")}
            </Button>
          ) : (
            <Button
              loading={isDisabled}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              endAdornment={<NavigateNextIcon />}>
              {intl.get("t_general_save_and_next")}
            </Button>
          )}
        </>
      </Stack>
    </Box>
  );
};

export default SkillRequirement;

import {
  Skeleton,
  Stack,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from "@mui/material";

import SkeletonAvatar from "@skeletons/SkeletonAvatar";
import SkeletonStatus from "@skeletons/SkeletonStatus";
import SkeletonTypography from "@skeletons/SkeletonTypography";

import DataTableWrapper from "@components/DataTable/DataTableWrapper";
import Typography from "@components/Typography";

import { intl } from "@utils/translate";

interface Column {
  id: "candidate" | "jobTitle" | "stage" | "activityDate";
  label: string;
  align?: "left" | "right" | "center";
}

const COLUMNS: ReadonlyArray<Column> = [
  {
    id: "candidate",
    label: "t_general_candidate",
    align: "left"
  },
  {
    id: "jobTitle",
    label: "t_general_job_title"
  },
  {
    id: "stage",
    label: "t_general_stage"
  },
  {
    id: "activityDate",
    label: "t_general_invitation_date"
  }
];

const ROWS = new Array(10).fill({
  candidate: (
    <Stack direction="row" alignItems="center" gap={1} width="100%">
      <SkeletonAvatar size="medium" />
      <Stack width="calc(100% - 2.5rem)">
        <SkeletonTypography variant="subtitle5" />
        <SkeletonTypography variant="body2" />
      </Stack>
    </Stack>
  ),
  jobTitle: <SkeletonTypography variant="body2" width="short" />,
  stage: (
    <Stack alignItems="center">
      <SkeletonStatus />
    </Stack>
  ),
  activityDate: <SkeletonTypography variant="body2" width="short" />
});

const SkeletonJobInvitationDataTable = () => {
  return (
    <>
      <DataTableWrapper>
        <TableHead>
          <TableRow>
            {COLUMNS?.map((singleColumn) => (
              <TableCell
                key={singleColumn.id}
                width="16.66%"
                align={singleColumn?.align ?? "center"}>
                <Typography variant="subtitle5">
                  {singleColumn.label ? intl.get(singleColumn.label) : ""}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {ROWS?.map((singleRow, index) => (
            <TableRow key={index}>
              {COLUMNS?.map((singleColumn) => (
                <TableCell
                  key={singleColumn.id}
                  align={singleColumn.align ?? "center"}>
                  {singleRow[singleColumn.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter sx={{ mr: 0 }}>
          <TableRow>
            <TableCell colSpan={5}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={3.25}
                height={48}>
                <Skeleton variant="rounded" height="1rem" width="6rem" />
                <Skeleton variant="rounded" height="1rem" width="4rem" />
                <Skeleton variant="rounded" height="1rem" width="2rem" />
                <Skeleton variant="rounded" height="1rem" width="2rem" />
              </Stack>
            </TableCell>
          </TableRow>
        </TableFooter>
      </DataTableWrapper>
    </>
  );
};

export default SkeletonJobInvitationDataTable;

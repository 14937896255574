import { LocalFireDepartment as LocalFireDepartmentIcon } from "@mui/icons-material";
import { Box, styled } from "@mui/material";

import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";

import Intl from "@interfaces/database/Intl";

import {
  CANDIDATE_STATUS_TAGS,
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  JOB_POSTING_STATUS,
  JOB_STATUS_TAGS,
  TEAM_MEMBER_ACCOUNT_TYPE,
  USER_TYPE
} from "@utils/config";
import theme, { colorPalette } from "@utils/theme";
import { intl as globalIntl } from "@utils/translate";

interface StatusProps {
  label:
    | typeof CANDIDATE_STATUS_TAGS[keyof typeof CANDIDATE_STATUS_TAGS]
    | typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]
    | typeof JOB_APPLICATION_INVITATION_ACTION_TYPE[keyof typeof JOB_APPLICATION_INVITATION_ACTION_TYPE]
    | typeof JOB_POSTING_STATUS[keyof typeof JOB_POSTING_STATUS]
    | typeof TEAM_MEMBER_ACCOUNT_TYPE[keyof typeof TEAM_MEMBER_ACCOUNT_TYPE];
  labelText?: string;
  labelTextColor?: string;
  bgColor?: string;
  intl?: Intl;
}

const StyledStatus = styled(Box)<{ backgroundColor: string; color: string }>(
  ({ backgroundColor, color }) => {
    return {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      columnGap: "0.125rem",
      padding: "0.25rem 0.75rem",
      borderRadius: "0.125rem",
      backgroundColor,
      color
    };
  }
);

const StyledFireIcon = styled(LocalFireDepartmentIcon)(() => {
  return {
    fontSize: "1.25rem"
  };
});

const Status = ({
  label,
  labelText = "",
  labelTextColor,
  bgColor,
  intl = globalIntl
}: StatusProps) => {
  const userProfile = useUserProfile();
  let color = "";
  let backgroundColor = "";
  let status = "";
  switch (label) {
    case JOB_STATUS_TAGS.NEW:
      status = intl.get("t_general_new");
      color = colorPalette.lighterBlue.base;
      backgroundColor = colorPalette.lighterBlue[20];
      break;

    case JOB_STATUS_TAGS.ACTIVE:
      status = intl.get("t_general_active_job");
      color = theme.palette.secondary.main;
      backgroundColor = theme.palette.secondary.light;
      break;

    case CANDIDATE_STATUS_TAGS.ACTIVE:
      status = intl.get("t_general_active_candidate");
      color = theme.palette.secondary.main;
      backgroundColor = theme.palette.secondary.light;
      break;

    case JOB_APPLICATION_INVITATION_ACTION_TYPE.APPLIED:
      status = intl.get("t_general_applied");
      color = theme.palette.warning.main;
      backgroundColor = theme.palette.warning.light;
      break;

    case JOB_APPLICATION_INVITATION_ACTION_TYPE.REVOKED:
      status = intl.get("t_general_revoked");
      color = theme.palette.error.main;
      backgroundColor = theme.palette.error.light;
      break;

    case JOB_APPLICATION_INVITATION_ACTION_TYPE.DECLINED:
      status = intl.get(
        userProfile.value?.user_type === USER_TYPE.INDIVIDUAL
          ? "t_general_employer_declined_candidate_view"
          : "t_general_employer_declined_employer_view"
      );
      color = theme.palette.error.main;
      backgroundColor = theme.palette.error.light;
      break;

    case JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED:
      status = intl.get("t_general_invited");
      color = theme.palette.primary.main;
      backgroundColor = colorPalette.blue[20];
      break;

    case JOB_APPLICATION_INVITATION_ACTION_TYPE.INVITED_DECLINED:
      status = intl.get("t_general_invited_declined");
      color = theme.palette.error.main;
      backgroundColor = theme.palette.error.light;
      break;

    case JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRING_FLOW_STEP_CHANGED:
      status = labelText;
      color = theme.palette.success.main;
      backgroundColor = theme.palette.success.light;
      break;

    case JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED:
      status = intl.get("t_general_hired");
      color = theme.palette.primary.main;
      backgroundColor = colorPalette.blue[20];
      break;

    case JOB_STATUS_TAGS.AGENCY:
      status = intl.get("t_general_agency");
      color = theme.palette.primary.main;
      backgroundColor = colorPalette.blue[20];
      break;

    case JOB_POSTING_STATUS.DRAFT:
      status = intl.get("t_general_draft");
      color = theme.palette.info.main;
      backgroundColor = theme.palette.info.light;
      break;

    case JOB_POSTING_STATUS.OK_AUTO_REVIEWED:
    case JOB_POSTING_STATUS.OK_MANUAL_REVIEWED:
      status = intl.get("t_general_active_job");
      color = theme.palette.success.main;
      backgroundColor = theme.palette.success.light;
      break;

    case JOB_POSTING_STATUS.SUBMITTED_FOR_AUTO_REVIEW:
    case JOB_POSTING_STATUS.SUBMITTED_FOR_MANUAL_REVIEW:
      status = intl.get("t_general_pending");
      color = theme.palette.warning.main;
      backgroundColor = theme.palette.warning.light;
      break;

    case JOB_POSTING_STATUS.FLAGGED:
    case JOB_POSTING_STATUS.HIDDEN:
      status = intl.get("t_general_inactive");
      color = theme.palette.error.main;
      backgroundColor = theme.palette.error.light;
      break;

    case TEAM_MEMBER_ACCOUNT_TYPE.INTERNAL:
      status = intl.get(
        "t_employer_manage_team_company_manager_invitation_type_internal"
      );
      color = theme.palette.success.main;
      backgroundColor = theme.palette.success.light;
      break;

    case TEAM_MEMBER_ACCOUNT_TYPE.EXTERNAL:
      status = intl.get(
        "t_employer_manage_team_company_manager_invitation_type_external"
      );
      color = theme.palette.primary.main;
      backgroundColor = colorPalette.blue[20];
      break;

    case TEAM_MEMBER_ACCOUNT_TYPE.OWNER:
      status = intl.get(
        "t_employer_manage_team_company_manager_invitation_type_owner"
      );
      color = theme.palette.success.main;
      backgroundColor = theme.palette.success.light;
      break;
  }

  return (
    <StyledStatus
      backgroundColor={bgColor ? bgColor : backgroundColor}
      color={labelTextColor ? labelTextColor : color}>
      <Box display="flex" alignItems="center">
        {label === JOB_STATUS_TAGS.ACTIVE ||
        label === CANDIDATE_STATUS_TAGS.ACTIVE ? (
          <StyledFireIcon />
        ) : (
          false
        )}
        <Typography variant="subtitle5">
          {labelText ? labelText : status}
        </Typography>
      </Box>
    </StyledStatus>
  );
};

export default Status;

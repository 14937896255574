import { useEffect, useState } from "react";

import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { NavigateBefore as NavigateBeforeIcon } from "@mui/icons-material";
import { Box, Grid, Stack } from "@mui/material";
import * as yup from "yup";

import SkeletonJobDetailForm from "@skeletons/SkeletonJobDetailForm";

import AutoCompleteTextField from "@components/AutoCompleteTextField";
import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import DatePicker from "@components/DatePicker";
import NonTypeableSelect from "@components/NonTypeableSelect";
import Paper from "@components/Paper";
import JobDetailFormDialog from "@components/ResumeForms/JobDetailFormDialog";
import TextField from "@components/TextField";
import TypeableSelect from "@components/TypeableSelect";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";

import KeyLabel from "@interfaces/components/KeyLabel";

import {
  FREE_TEXT_FIELD_MAX_LENGTH,
  JOB_CONTRACT_TYPE,
  JOB_CONTRACT_TYPE_T_LABELS,
  JOB_DETAILS_CURRENT_JOB_CHECKBOX_VALUES,
  LOCALE_SHORT
} from "@utils/config";
import { getCityList } from "@utils/keyLabelHandlers/location";
import { getDropdownCountryList } from "@utils/location";
import translate, { intl } from "@utils/translate";

interface JobDetailForm {
  companyName?: string;
  jobTitle?: string;
  contractType?: string;
  country?: KeyLabel;
  city?: KeyLabel;
  responsibilities?: string;
  isCurrentJob?: Array<number>;
  startDate?: Date | string;
  endDate?: Date | string;
}

interface JobDetailFormProps {
  initialValues?: JobDetailForm;
  formLanguage?: typeof LOCALE_SHORT[keyof typeof LOCALE_SHORT];
}

const JobDetailForm = ({
  initialValues,
  formLanguage = LOCALE_SHORT.JA
}: JobDetailFormProps) => {
  const navigate = useNavigate();
  const userData = useUserProfile();
  const [loading, setLoading] = useState<boolean>(true);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const schema = yup.object({
    companyName: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_resumes_job_detail_form_label_company_name"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_resumes_job_detail_form_label_company_name")
        })
      ),
    jobTitle: yup
      .string()
      .trim()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_job_title"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_job_title")
        })
      ),
    contractType: yup.string().required(
      intl.get("t_error_required", {
        field: intl.get("t_resumes_job_detail_form_label_contract_type")
      })
    ),
    country: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .test(
        "country",
        intl.get("t_error_required", {
          field: intl.get("t_general_country")
        }),
        (value) => (value?.key && value?.label ? true : false)
      )
      .nullable(),
    city: yup
      .object()
      .shape({
        key: yup.string().trim(),
        label: yup.string().trim()
      })
      .test(
        "label",
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_city"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        }),
        (value) => {
          return value && value.label
            ? value.label.length <= FREE_TEXT_FIELD_MAX_LENGTH
            : true;
        }
      )
      .nullable(),
    responsibilities: yup
      .string()
      .trim()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_resumes_job_detail_form_label_responsibilities")
        })
      )
      .max(
        5000,
        intl.get("t_error_max_limit", {
          field: intl.get("t_resumes_job_detail_form_label_responsibilities"),
          maxLimit: 5000
        })
      ),
    isCurrentJob: yup.array().test(
      "isCurrentJob",
      intl.get("t_error_invalid", {
        field: intl.get("t_resumes_job_detail_form_is_current_job")
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (value: any) => {
        if (
          value?.length === 0 ||
          value?.includes(
            JOB_DETAILS_CURRENT_JOB_CHECKBOX_VALUES.IS_CURRENT_JOB_CHECKED
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    ),
    startDate: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_resumes_job_detail_form_label_start_date")
        })
      )
      .typeError(
        intl.get("t_error_invalid", {
          field: intl.get("t_resumes_job_detail_form_label_start_date")
        })
      ),
    endDate: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .min(
        yup.ref("startDate"),
        intl.get("t_error_minimum_date", {
          endDate: intl.get("t_resumes_job_detail_form_label_end_date"),
          startDate: intl.get("t_resumes_job_detail_form_label_start_date")
        })
      )
      .when("isCurrentJob", {
        is: (isCurrentJob: Array<number>) => isCurrentJob?.includes(1),
        then: (schema) => {
          return schema.notRequired();
        },
        otherwise: (schema) => {
          return schema.required(
            intl.get("t_error_required", {
              field: intl.get("t_resumes_job_detail_form_label_end_date")
            })
          );
        }
      })
      .typeError(
        intl.get("t_error_invalid", {
          field: intl.get("t_resumes_job_detail_form_label_end_date")
        })
      )
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, setValue, watch, formState } = methods;

  const selectedCountryIsoCode = useWatch({
    control,
    name: "country.key"
  });

  useEffect(() => {
    // check selected country is modified or not
    if (formState.dirtyFields.country) {
      setValue("city", { key: "", label: "" });
    }
  }, [selectedCountryIsoCode, setValue]);

  const hasNoEndDate = watch("isCurrentJob")?.includes(1); // array contain 1 => currently working checkbox is checked else, unchecked

  useEffect(() => {
    if (hasNoEndDate) {
      setValue("endDate", "");
    }
  }, [hasNoEndDate]);

  const CONTRACT_TYPE_OPTIONS = useOptions(
    JOB_CONTRACT_TYPE,
    JOB_CONTRACT_TYPE_T_LABELS
  );
  const COUNTRY_OPTIONS = getDropdownCountryList();

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleFormSubmit = () => {
    setIsDialogOpen(true);
  };

  if (loading || userData.loading) {
    return <SkeletonJobDetailForm />;
  }

  return (
    <Paper>
      <Typography variant="h3">
        {intl.get("t_resumes_job_detail_title")}
      </Typography>
      <br />
      <Typography variant="body1" color="text.secondary" mt={2.5}>
        {intl.get("t_resumes_add_job_detail_form_subtitle")}
      </Typography>
      <br />
      <Typography mb={3} color="text.secondary">
        {formLanguage === LOCALE_SHORT.JA
          ? intl.get("t_resumes_form_subtitle_lang_japanese")
          : intl.get("t_resumes_form_subtitle_lang_english")}
      </Typography>
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container columnSpacing={3}>
          <Grid item xs={12}>
            <TextField
              data-testid="resumes_job_detail_company_name_input"
              name="companyName"
              label={intl.get("t_resumes_job_detail_form_label_company_name")}
              placeholder={intl.get(
                "t_resumes_job_detail_form_label_company_name"
              )}
              control={control}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              data-testid="resumes_job_detail_job_title_input"
              name="jobTitle"
              label={intl.get("t_general_job_title")}
              placeholder={intl.get("t_general_job_title")}
              control={control}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <NonTypeableSelect
              data-testid="resumes_job_detail_contract_type_select"
              name="contractType"
              label={intl.get("t_resumes_job_detail_form_label_contract_type")}
              placeholder={intl.get(
                "t_resumes_job_detail_form_label_contract_type"
              )}
              control={control}
              setValue={setValue}
              options={CONTRACT_TYPE_OPTIONS}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TypeableSelect
              data-testid="resumes_job_detail_country_select"
              name="country"
              label={intl.get("t_general_country")}
              placeholder={intl.get(
                "t_resumes_job_detail_form_label_country_placeholder"
              )}
              control={control}
              options={COUNTRY_OPTIONS}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <AutoCompleteTextField
              data-testid="resumes_job_detail_city_input"
              name="city"
              label={intl.get("t_general_city")}
              placeholder={intl.get(
                "t_resumes_job_detail_form_label_city_placeholder"
              )}
              control={control}
              disabled={!selectedCountryIsoCode}
              setValue={setValue}
              getOptions={(subText) => {
                return getCityList(subText, selectedCountryIsoCode);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Checkbox
              data-testid="resumes_job_detail_is_current_job_checkbox"
              size="medium"
              name="isCurrentJob"
              control={control}
              setValue={setValue}
              options={[
                {
                  label: intl.get(
                    "t_resumes_job_detail_form_label_is_current_job_check"
                  ),
                  value:
                    JOB_DETAILS_CURRENT_JOB_CHECKBOX_VALUES.IS_CURRENT_JOB_CHECKED
                }
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              data-testid="resumes_job_detail_start_date_input"
              name="startDate"
              label={intl.get("t_resumes_job_detail_form_label_start_date")}
              control={control}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              data-testid="resumes_job_detail_end_date_input"
              name="endDate"
              label={intl.get("t_resumes_job_detail_form_label_end_date")}
              control={control}
              required={!hasNoEndDate}
              disabled={hasNoEndDate}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              data-testid="resumes_job_detail_responsibilities_input"
              name="responsibilities"
              label={intl.get(
                "t_resumes_job_detail_form_label_responsibilities"
              )}
              placeholder={intl.get(
                "t_resumes_job_detail_placeholder_responsibilities"
              )}
              required
              control={control}
              multiline
              maxLength={5000}
              rows={3}
            />
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="space-between">
          <Button
            data-testid="resumes_job_detail_back_button"
            variant="outlined"
            startAdornment={<NavigateBeforeIcon />}
            handleClick={() =>
              navigate(
                `/${translate.getCurrentLocale()}/profile/resume/${
                  formLanguage === LOCALE_SHORT.JA ? "ja-cv" : "en-cv"
                }`
              )
            }>
            {intl.get("t_general_back")}
          </Button>

          {isDialogOpen ? (
            <JobDetailFormDialog
              initialValues={{
                ...watch()
              }}
              formLanguage={formLanguage}
              isDialogOpen={isDialogOpen}
              setIsDialogOpen={setIsDialogOpen}
            />
          ) : (
            <Button type="submit" data-testid="resumes_job_detail_save_button">
              {intl.get("t_general_save")}
            </Button>
          )}
        </Stack>
      </Box>
    </Paper>
  );
};

export default JobDetailForm;

import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon
} from "@mui/icons-material";
import { Box, DialogActions, DialogContent, Stack } from "@mui/material";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import { useAuthState } from "react-firebase-hooks/auth";
import * as yup from "yup";

import SkeletonInput from "@skeletons/SkeletonInput";
import SkeletonKeyword from "@skeletons/SkeletonKeyword";

import Avatar from "@components/Avatar";
import Button from "@components/Button";
import CVFilledStatus from "@components/CVFilledStatus";
import Dialog from "@components/Dialog";
import Icon from "@components/Icon";
import InformationField from "@components/InformationField";
import { commonFavoriteIconStyle } from "@components/JobCards/JobCard";
import Status from "@components/Status";
import Tag from "@components/Tag";
import TypeableSelect from "@components/TypeableSelect";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import useToast from "@hooks/useToast";

import KeyLabel from "@interfaces/components/KeyLabel";
import Location from "@interfaces/database/Location";
import Skill from "@interfaces/database/Skill";
import UserID from "@interfaces/database/UserID";

import {
  CANDIDATE_ACTIVE_TAG_LAST_LOGIN_DAY,
  CANDIDATE_STATUS_TAGS,
  INDIVIDUAL_USER_NUMBER_OF_COMPANIES,
  INDIVIDUAL_USER_VISA_TYPE,
  INDIVIDUAL_USER_VISA_TYPE_T_LABELS,
  JA_LANGUAGE_TEST,
  JA_LANGUAGE_TEST_T_LABELS,
  LANGUAGE_PROFICIENCY,
  LANGUAGE_PROFICIENCY_T_LABELS,
  YEARS_OF_EXPERIENCE,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import { getDaysAgo } from "@utils/dateTime";
import { auth } from "@utils/firebase";
import { handleInviteCandidate } from "@utils/inviteCandidate";
import { jaTestResultFinder } from "@utils/jaTestResultFinder";
import { getSkillLabel } from "@utils/keyLabelHandlers/skill";
import { getLanguageName } from "@utils/language";
import { getCountryName, getFullLocation } from "@utils/location";
import { roundSalary } from "@utils/roundSalary";
import theme, { colorPalette } from "@utils/theme";
import translate, { intl } from "@utils/translate";

import VisaIcon from "@assets/images/Visa.svg";

dayjs.extend(relative);

export interface CandidateProfileCardProps {
  candidateId?: UserID;
  initials?: string;
  jobTitleId?: number;
  profilePicUrl?: string;
  lastLoggedIn?: number;
  location?: Location;
  countryOfOrigin?: string;
  visaType?: typeof INDIVIDUAL_USER_VISA_TYPE[keyof typeof INDIVIDUAL_USER_VISA_TYPE];
  noOfCompanies?: typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES[keyof typeof INDIVIDUAL_USER_NUMBER_OF_COMPANIES];
  expectedSalary?: number;
  experiences?: Array<{
    jobTitle: string;
    yearsOfExperience: typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE];
  }>;
  noJobExperience?: boolean;
  languages?: Array<{
    name: string;
    proficiency: typeof LANGUAGE_PROFICIENCY[keyof typeof LANGUAGE_PROFICIENCY];
    testName?: string;
    testResult?: string;
  }>;
  // FIXME: Use Skill interface
  skills?: Array<{
    name: string;
    yearsOfExperience: typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE];
  }>;
  attachedResumes?: {
    englishCV: boolean;
    japaneseCV: boolean;
    japaneseRirekisho: boolean;
  };
  isBookmarked?: boolean;
  handleClick?: () => void;
  handleCandidateBookmark?: () => void;
  jobTitleOptions: Array<KeyLabel>;
  isJobTitleOptionsLoading: boolean;
}

const CandidateProfileCard = ({
  candidateId,
  initials,
  jobTitleId = 0,
  profilePicUrl,
  location,
  visaType,
  countryOfOrigin = "",
  noOfCompanies,
  expectedSalary = -1,
  experiences = [],
  noJobExperience = false,
  languages = [],
  skills: candidateSkills = [],
  attachedResumes,
  lastLoggedIn,
  isBookmarked = false,
  handleCandidateBookmark,
  handleClick,
  jobTitleOptions,
  isJobTitleOptionsLoading = false
}: CandidateProfileCardProps) => {
  const locationData = useLocation();
  const queryParams = new URLSearchParams(locationData.search);
  const inviteJobId = queryParams.get("invite_job_id");
  const [skills, setSkills] = useState<Array<Skill>>([]);
  const [user] = useAuthState(auth);
  const userProfile = useUserProfile();
  const toast = useToast();
  const [isInvited, setIsInvited] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false); // invite button loading state
  const [isSkillsLoading, setIsSkillsLoading] = useState<boolean>(true);

  const userLocation = getFullLocation(location?.city, location?.country, true);

  useEffect(() => {
    (async () => {
      setIsSkillsLoading(true);
      const skills = [];

      for (const singleSkill of candidateSkills) {
        const skillLabel = await getSkillLabel(
          singleSkill.name,
          translate.getCurrentLocale()
        );
        skills.push({
          name: skillLabel,
          years_of_experience: singleSkill.yearsOfExperience
        });
      }

      setSkills(skills);
      setIsSkillsLoading(false);
    })();
  }, []);

  // validation schema
  const schema = yup.object({
    inviteJobId: yup
      .object()
      .shape({
        key: yup.string(),
        label: yup.string()
      })
      .test(
        "inviteJobId",
        intl.get("t_error_required", {
          field: intl.get("t_general_job_title")
        }),
        (value) => (value?.key && value?.label ? true : false)
      )
      .nullable()
  });

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, setValue } = methods;

  useEffect(() => {
    const jobTitleLabel =
      jobTitleOptions.find(
        (singleJobTitleOption) => singleJobTitleOption.key === inviteJobId
      )?.label ?? "";
    if (inviteJobId && jobTitleLabel) {
      setValue("inviteJobId", {
        key: inviteJobId,
        label: jobTitleLabel
      });
    }
  }, [inviteJobId]);

  const handleCandidateInvite = async (formData: {
    inviteJobId?: KeyLabel;
  }) => {
    if (isJobTitleOptionsLoading) {
      return;
    }

    try {
      setIsLoading(true);
      if (
        candidateId &&
        formData?.inviteJobId?.key &&
        formData?.inviteJobId?.label &&
        userProfile?.value?.company_id &&
        user
      ) {
        const isInvited = await handleInviteCandidate(
          formData?.inviteJobId?.key,
          candidateId,
          userProfile.value.company_id,
          userProfile,
          user?.uid
        );
        if (isInvited) {
          setIsInvited(true);
          toast.kampai(intl.get("t_toast_success_invitation"), "success");
        } else {
          setIsInvited(false);
          toast.kampai(intl.get("t_toast_error_invitation"), "error");
        }
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toast.kampai(intl.get("t_toast_error_invitation"), "error");
    }
  };

  const CurrentFavoriteIcon = () =>
    isBookmarked ? (
      <FavoriteIcon
        color="error"
        sx={commonFavoriteIconStyle}
        onClick={(e) => {
          e.stopPropagation();
          handleCandidateBookmark ? handleCandidateBookmark() : false;
        }}
      />
    ) : (
      <FavoriteBorderIcon
        color="error"
        sx={commonFavoriteIconStyle}
        onClick={(e) => {
          e.stopPropagation();
          handleCandidateBookmark ? handleCandidateBookmark() : false;
        }}
      />
    );

  return (
    <Box
      p={2}
      borderRadius={1}
      bgcolor="common.white"
      onClick={handleClick}
      sx={{
        "&:hover": {
          boxShadow: `0px 3px 8px ${colorPalette.black.hover["30"]}`,
          transform: "scale(1.01)"
        },
        "transition": "transform 0.3s ease"
      }}>
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        alignItems="flex-start"
        justifyContent="space-between"
        width="100%"
        mb={1}>
        {/* User Details */}
        <Stack direction="row" alignItems="center" gap={1}>
          <Avatar imgSrc={profilePicUrl} initials={initials} />
          <Box>
            <Stack direction="row" gap={2}>
              <Typography variant="subtitle2">
                {noJobExperience
                  ? intl.get("t_general_fresher")
                  : experiences?.[jobTitleId]?.jobTitle ?? "-"}
              </Typography>
              {lastLoggedIn &&
              getDaysAgo(new Date(lastLoggedIn * 1000)) <=
                CANDIDATE_ACTIVE_TAG_LAST_LOGIN_DAY ? (
                <Status label={CANDIDATE_STATUS_TAGS.ACTIVE} />
              ) : (
                false
              )}
            </Stack>
            <Typography
              variant="body1"
              color={theme.palette.text.secondary}
              textTransform="capitalize">
              {userLocation ?? "-"}
            </Typography>
            <br />
          </Box>
        </Stack>
        {/* action buttons start */}
        <Stack ml="auto" direction="row" alignItems="center" gap={2}>
          {handleCandidateBookmark ? <CurrentFavoriteIcon /> : false}

          {isInvited ? (
            <Box width="max-content">
              <Button disabled>{intl.get("t_general_invited")}</Button>
            </Box>
          ) : (
            <Dialog
              title={intl.get(
                "t_employer_search_page_invite_candidate_modal_title"
              )}
              maxWidth="sm"
              isStopEventPropagation
              initiator={
                <Box width="max-content">
                  <Button loading={isLoading}>
                    {intl.get("t_general_invite")}
                  </Button>
                </Box>
              }>
              {(handleAgree, handleCancel) => (
                <>
                  <Box
                    noValidate
                    component="form"
                    onSubmit={handleSubmit(handleCandidateInvite)}>
                    <DialogContent>
                      <Typography color="text.secondary" mb={2}>
                        {intl.get(
                          "t_employer_search_page_invite_candidate_modal_content"
                        )}
                      </Typography>
                      {isJobTitleOptionsLoading ? (
                        <SkeletonInput hasLabel={false} />
                      ) : (
                        <TypeableSelect
                          disablePortal={false}
                          name="inviteJobId"
                          control={control}
                          placeholder={intl.get(
                            "t_employer_search_page_invite_candidate_modal_placeholder"
                          )}
                          required
                          options={jobTitleOptions}
                        />
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button handleClick={handleCancel} variant="outlined">
                        {intl.get("t_general_cancel")}
                      </Button>
                      <Button type="submit">
                        {intl.get("t_general_invite")}
                      </Button>
                    </DialogActions>
                  </Box>
                </>
              )}
            </Dialog>
          )}
        </Stack>
        {/* action buttons end */}
      </Stack>

      <Stack spacing={1} width="100%">
        {/* Basic Information */}
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={2}
          flexWrap="wrap"
          alignItems={{ md: "center" }}>
          <InformationField
            icon="home"
            label={countryOfOrigin ? getCountryName(countryOfOrigin) : "-"}
          />
          {visaType ? (
            <InformationField
              imgSrc={VisaIcon}
              imgAlt={intl.get("t_alt_visa_types")}
              label={
                visaType
                  ? intl.get(INDIVIDUAL_USER_VISA_TYPE_T_LABELS[visaType])
                  : "-"
              }
            />
          ) : (
            false
          )}
          {/* Not displayed when user have no job experience (fresher)*/}
          {!noJobExperience ? (
            <InformationField
              icon="apartment"
              label={`${
                noOfCompanies
                  ? intl.get("t_resumes_basic_info_companies", {
                      noOfCompanies
                    })
                  : "-"
              } `}
            />
          ) : (
            false
          )}

          <InformationField
            icon="currency_yen"
            label={
              expectedSalary
                ? intl.get("t_resumes_basic_info_rounded_salary", {
                    expectedSalary: roundSalary(expectedSalary)
                  })
                : "-"
            }
          />
        </Stack>

        {/* Experience */}
        <Typography variant="subtitle2">
          {intl.get("t_profile_summary_experience")}
        </Typography>
        {experiences?.length > 0 ? (
          <Stack gap={1} flexWrap="wrap" direction="row">
            {experiences?.map((singleExperience, index) => (
              <Stack direction="row" alignItems="center" key={index}>
                <Icon type="work" filled />
                <Box ml={1.25} component="span">
                  <Typography variant="subtitle4">
                    {singleExperience.jobTitle},&nbsp;
                  </Typography>
                  <Typography variant="body1" whiteSpace="nowrap">
                    {intl.get(
                      YEARS_OF_EXPERIENCE_T_LABELS[
                        singleExperience.yearsOfExperience
                      ]
                    )}
                  </Typography>
                </Box>
              </Stack>
            ))}
          </Stack>
        ) : (
          <Typography variant="body1">
            {noJobExperience ? intl.get("t_general_fresher") : "-"}
          </Typography>
        )}

        {/* Language */}
        <Typography variant="subtitle2">
          {intl.get("t_profile_summary_language")}
        </Typography>
        {languages?.length > 0 ? (
          <Stack direction="column" gap={1}>
            {languages?.map((singleLanguage, index) => (
              <Box component="span" key={index}>
                <Typography variant="subtitle4">
                  {intl.get("t_language_proficiency_level", {
                    language: getLanguageName(singleLanguage?.name)
                  })}
                  &nbsp;
                </Typography>
                <Typography variant="body1">
                  {intl.get(
                    LANGUAGE_PROFICIENCY_T_LABELS[singleLanguage?.proficiency]
                  )}
                  {index === 0 && singleLanguage?.testName ? (
                    // first index (index = 0) is always japanese language
                    <>
                      {singleLanguage?.testName &&
                      Object.values(JA_LANGUAGE_TEST).includes(
                        singleLanguage.testName as keyof typeof JA_LANGUAGE_TEST_T_LABELS // check first language test should be valid japanese test
                      )
                        ? `, ${intl.get(
                            JA_LANGUAGE_TEST_T_LABELS[
                              singleLanguage.testName as keyof typeof JA_LANGUAGE_TEST_T_LABELS
                            ]
                          )}: ${jaTestResultFinder(
                            singleLanguage.testName,
                            singleLanguage.testResult
                          )}`
                        : false}
                    </>
                  ) : (
                    <>
                      {singleLanguage?.testName
                        ? `, ${singleLanguage.testName}: ${singleLanguage.testResult}`
                        : false}
                    </>
                  )}
                </Typography>
              </Box>
            ))}
          </Stack>
        ) : (
          <Typography variant="body1">-</Typography>
        )}

        {/* Skills */}
        <Typography variant="subtitle2">
          {intl.get("t_profile_summary_skill")}
        </Typography>

        {isSkillsLoading ? (
          <SkeletonKeyword multiple />
        ) : (
          <>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {skills?.length > 0 ? (
                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {skills?.map((singleSkill, index) => (
                    <Tag
                      label={`${singleSkill.name} ${intl.get(
                        YEARS_OF_EXPERIENCE_T_LABELS[
                          singleSkill.years_of_experience
                        ]
                      )}`}
                      key={index}
                    />
                  ))}
                </Stack>
              ) : (
                <Typography variant="body1">-</Typography>
              )}
            </Stack>
          </>
        )}

        {/* FIXME: Update "Attached Resumes" title */}
        {/* Attached Resumes */}
        <Typography variant="subtitle2">
          {intl.get("t_profile_summary_attached_resumes")}
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={1}
          flexWrap="wrap"
          width="100%"
          alignItems={{ xs: "start", sm: "center" }}>
          <CVFilledStatus
            label={intl.get("t_general_english_cv")}
            isAttached={attachedResumes?.englishCV ?? false}
          />
          <CVFilledStatus
            label={intl.get("t_general_japanese_cv")}
            isAttached={attachedResumes?.japaneseCV ?? false}
          />
          <CVFilledStatus
            label={intl.get("t_general_japanese_rirekisho")}
            isAttached={attachedResumes?.japaneseRirekisho ?? false}
          />
          {lastLoggedIn ? (
            <Typography variant="body2" color="text.secondary" ml="auto">
              {intl.get("t_general_last_logged_in", {
                time: dayjs
                  .unix(lastLoggedIn)
                  .locale(translate.getCurrentLocaleShort())
                  .fromNow()
              })}
            </Typography>
          ) : (
            false
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default CandidateProfileCard;

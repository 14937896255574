import { useEffect, useState } from "react";

import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import {
  Edit as EditIcon,
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon
} from "@mui/icons-material";
import { Divider, Stack, Tab } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";

import SkeletonButton from "@skeletons/SkeletonButton";
import SkeletonJobDetailCard from "@skeletons/SkeletonJobDetailCard";

import Button from "@components/Button";
import JobDetailCard from "@components/JobDetailCard";
import Paper from "@components/Paper";
import { TabPanel, Tabs } from "@components/Tab";
import Typography from "@components/Typography";

import useToast from "@hooks/useToast";

import CompanyProfile from "@interfaces/database/CompanyProfile";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";
import UploadedFileInformation from "@interfaces/database/UploadedFileInformation";
import JobProfileWrapped from "@interfaces/functions/JobProfileWrapped";

import {
  FILES_LOCATION_COMPANY_LOGO,
  FIRESTORE_COLLECTIONS,
  JOB_APPLY_STATUS_CODE,
  JOB_NEW_TAG_LAST_UPDATED_DAY,
  JOB_STATUS_TAGS,
  LOCALE,
  LOCALE_SHORT,
  TOTAL_JOB_POSTING_STEPS
} from "@utils/config";
import { getDaysAgo } from "@utils/dateTime";
import { db } from "@utils/firebase";
import { getFileURL } from "@utils/getFileURL";
import { resolveMultiLingual } from "@utils/multiLingual";
import Timestamp from "@utils/Timestamp";
import translate, { intl, intlJa } from "@utils/translate";
const ENGLISH_TAB_VALUE = 0;
const JAPANESE_TAB_VALUE = 1;

const Confirmation = () => {
  const { job_id: jobId, data_locale: dataLocale } = useParams();
  const navigate = useNavigate();
  const {
    jobData,
    handleSetJobData
  }: {
    jobData: JobProfile;
    handleSetJobData: (
      jobId: JobID,
      jobData: JobProfile,
      handleJobUpdateSuccess: (jobId: JobID) => void,
      handleJobUpdateFail: () => void
    ) => void;
  } = useOutletContext();
  const toast = useToast();

  const [jobDetails, setJobDetails] = useState<JobProfileWrapped>();
  const [isJobDataLoading, setIsJobDataLoading] = useState<boolean>(true);
  const [jobAdditionalFileMetaData, setJobAdditionalFileMetaData] = useState<
    UploadedFileInformation | undefined
  >(jobData?.additional_file);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [activeTabValue, setActiveTabValue] = useState<number>(
    dataLocale === LOCALE_SHORT.EN ? ENGLISH_TAB_VALUE : JAPANESE_TAB_VALUE
  );

  const handleJobUpdateSuccess = (updatedJobId: string) => {
    setIsDisabled(false);
    navigate(
      `/${translate.getCurrentLocale()}/employers/jobs/${updatedJobId}/${dataLocale}/success`
    );
    toast.kampai(intl.get("t_toast_success_job_post"), "success");
  };

  const handleJobUpdateFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  useEffect(() => {
    if (!jobId) {
      return;
    }
    setIsJobDataLoading(true);
    (async () => {
      if (jobData && jobData.company_id) {
        const companyRef = doc(
          db,
          FIRESTORE_COLLECTIONS.COMPANIES,
          jobData.company_id
        );
        const companySnap = await getDoc(companyRef);
        if (companySnap.exists()) {
          let companyData = companySnap.data() as CompanyProfile;

          let agencyData;
          if (jobData.client_company_id) {
            const clientCompanyRef = doc(
              db,
              FIRESTORE_COLLECTIONS.COMPANIES,
              jobData.client_company_id
            );
            const clientCompanySnap = await getDoc(clientCompanyRef);
            if (clientCompanySnap.exists()) {
              agencyData = companyData;
              companyData = clientCompanySnap.data() as CompanyProfile;
            }
          }

          setJobDetails({
            job_id: jobId,
            job_profile: jobData,
            company_profile: companyData,
            agency_profile: agencyData
          });
        }
      }
      setIsJobDataLoading(false);
    })();
  }, [jobId, jobData]);

  const statusTags: Array<
    typeof JOB_STATUS_TAGS[keyof typeof JOB_STATUS_TAGS]
  > = [JOB_STATUS_TAGS.ACTIVE];
  if (jobDetails?.job_profile.updated_at) {
    if (
      getDaysAgo(jobDetails?.job_profile.updated_at) <=
      JOB_NEW_TAG_LAST_UPDATED_DAY
    ) {
      statusTags.push(JOB_STATUS_TAGS.NEW);
    }
  }

  useEffect(() => {
    setJobAdditionalFileMetaData(jobData?.additional_file);
  }, [jobData?.additional_file]);

  const handleTabChange = (newValue: number) => {
    setActiveTabValue(newValue);
  };

  return (
    <>
      <Paper>
        <Stack direction={{ xs: "column", md: "row" }} gap={{ md: 10 }}>
          <Stack width={{ xs: "100%", md: "25%" }}>
            <Typography
              variant="subtitle2"
              mb={{ xs: 3, md: 8 }}
              color="secondary.main">
              {intl.get("t_general_step", { stepNumber: 8 })}/
              {TOTAL_JOB_POSTING_STEPS}
            </Typography>
            <Typography variant="h4" mb={2.5}>
              {intl.get("t_job_post_job_confirmation_step_label")}
            </Typography>
          </Stack>
          <Stack width={{ xs: "100%", md: "75%" }}>
            <Tabs value={activeTabValue} aria-label="applications-table">
              <Tab
                sx={{ p: 2 }}
                value={ENGLISH_TAB_VALUE}
                key={ENGLISH_TAB_VALUE}
                onClick={() => handleTabChange(ENGLISH_TAB_VALUE)}
                label={intl.get("t_general_english")}
                className={
                  activeTabValue === ENGLISH_TAB_VALUE ? "Mui-selected" : ""
                }
              />
              <Tab
                sx={{ p: 2 }}
                value={JAPANESE_TAB_VALUE}
                key={JAPANESE_TAB_VALUE}
                onClick={() => handleTabChange(JAPANESE_TAB_VALUE)}
                label={intl.get("t_general_japanese")}
                className={
                  activeTabValue === JAPANESE_TAB_VALUE ? "Mui-selected" : ""
                }
              />
            </Tabs>
            <Divider />
            <TabPanel
              value={activeTabValue}
              index={ENGLISH_TAB_VALUE}
              key={ENGLISH_TAB_VALUE}>
              <>
                <Stack direction="row-reverse" my={2.5}>
                  <Button
                    variant="outlined"
                    startAdornment={<EditIcon />}
                    handleClick={() =>
                      navigate(
                        `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/${
                          LOCALE_SHORT.EN
                        }/company-information`
                      )
                    }>
                    {intl.get(
                      "t_job_post_job_confirmation_step_edit_english_post_btn"
                    )}
                  </Button>
                </Stack>
                {isJobDataLoading || !jobDetails || !jobData ? (
                  <SkeletonJobDetailCard />
                ) : (
                  <JobDetailCard
                    mode="employer_preview"
                    visaSponsorship={
                      jobDetails?.job_profile?.job_overview?.visa_sponsorship
                    }
                    jobContractType={
                      jobDetails?.job_profile?.job_overview?.contract_type
                    }
                    remoteAllowed={
                      jobDetails?.job_profile?.job_overview?.remote_possible
                    }
                    jaProficiency={
                      jobDetails?.job_profile?.language_requirement?.at(0)
                        ?.proficiency
                    }
                    enProficiency={
                      jobDetails?.job_profile?.language_requirement?.at(1)
                        ?.proficiency
                    }
                    jobDescription={resolveMultiLingual(
                      jobDetails?.job_profile?.job_description,
                      LOCALE.EN
                    )}
                    experienceRequirement={
                      jobDetails?.job_profile.job_overview?.minimum_experience
                    }
                    mustRequirements={resolveMultiLingual(
                      jobDetails?.job_profile?.must_requirements,
                      LOCALE.EN
                    )}
                    languageRequirement={
                      jobDetails.job_profile.language_requirement
                    }
                    jobApplyStatusCode={JOB_APPLY_STATUS_CODE.HIDDEN}
                    jobStatuses={statusTags}
                    jobId={jobId ?? ""}
                    updatedDate={
                      new Date(jobDetails.job_profile.updated_at.seconds * 1000)
                    }
                    companyLogo={getFileURL(
                      FILES_LOCATION_COMPANY_LOGO,
                      jobDetails?.job_profile?.client_company_id
                        ? jobDetails.job_profile.client_company_id
                        : jobDetails?.job_profile?.company_id,
                      jobDetails?.company_profile?.logo?.extension,
                      jobDetails?.company_profile?.logo?.uploaded_at
                    )}
                    companyName={resolveMultiLingual(
                      jobDetails?.company_profile?.name,
                      LOCALE.EN
                    )}
                    companyWebsite={jobDetails?.company_profile?.website_url}
                    agencyName={resolveMultiLingual(
                      jobDetails?.agency_profile?.name,
                      LOCALE.EN
                    )}
                    jobTitle={resolveMultiLingual(
                      jobDetails?.job_profile?.job_title,
                      LOCALE.EN
                    )}
                    location={jobDetails?.job_profile?.job_overview?.location}
                    salary={jobDetails?.job_profile?.job_overview?.salary}
                    requiredSkills={jobDetails?.job_profile?.skills}
                    otherDetails={resolveMultiLingual(
                      jobDetails?.job_profile?.additional_information,
                      LOCALE.EN
                    )}
                    additionInfoFileDetails={jobAdditionalFileMetaData}
                    cvFileRequirements={
                      jobDetails?.job_profile?.cv_file_requirements
                    }
                    hiringFlow={jobDetails?.job_profile?.hiring_flow}
                  />
                )}
              </>
            </TabPanel>
            <TabPanel
              value={activeTabValue}
              index={JAPANESE_TAB_VALUE}
              key={JAPANESE_TAB_VALUE}>
              <>
                <Stack direction="row-reverse" my={2.5}>
                  <Button
                    variant="outlined"
                    startAdornment={<EditIcon />}
                    handleClick={() =>
                      navigate(
                        `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/${
                          LOCALE_SHORT.JA
                        }/company-information`
                      )
                    }>
                    {intl.get(
                      "t_job_post_job_confirmation_step_edit_japanese_post_btn"
                    )}
                  </Button>
                </Stack>
                {isJobDataLoading || !jobDetails || !jobData ? (
                  <SkeletonJobDetailCard />
                ) : (
                  <JobDetailCard
                    intl={intlJa}
                    mode="employer_preview"
                    visaSponsorship={
                      jobDetails?.job_profile?.job_overview?.visa_sponsorship
                    }
                    jobContractType={
                      jobDetails?.job_profile?.job_overview?.contract_type
                    }
                    remoteAllowed={
                      jobDetails?.job_profile?.job_overview?.remote_possible
                    }
                    jaProficiency={
                      jobDetails?.job_profile?.language_requirement?.at(0)
                        ?.proficiency
                    }
                    enProficiency={
                      jobDetails?.job_profile?.language_requirement?.at(1)
                        ?.proficiency
                    }
                    jobDescription={resolveMultiLingual(
                      jobDetails?.job_profile?.job_description,
                      LOCALE.JA
                    )}
                    experienceRequirement={
                      jobDetails?.job_profile.job_overview?.minimum_experience
                    }
                    mustRequirements={resolveMultiLingual(
                      jobDetails?.job_profile?.must_requirements,
                      LOCALE.JA
                    )}
                    languageRequirement={
                      jobDetails.job_profile.language_requirement
                    }
                    jobApplyStatusCode={JOB_APPLY_STATUS_CODE.HIDDEN}
                    jobStatuses={statusTags}
                    jobId={jobId ?? ""}
                    updatedDate={
                      new Date(jobDetails.job_profile.updated_at.seconds * 1000)
                    }
                    companyLogo={getFileURL(
                      FILES_LOCATION_COMPANY_LOGO,
                      jobDetails?.job_profile?.client_company_id
                        ? jobDetails.job_profile.client_company_id
                        : jobDetails?.job_profile?.company_id,
                      jobDetails?.company_profile?.logo?.extension,
                      jobDetails?.company_profile?.logo?.uploaded_at
                    )}
                    companyName={resolveMultiLingual(
                      jobDetails?.company_profile?.name,
                      LOCALE.JA
                    )}
                    companyWebsite={jobDetails?.company_profile?.website_url}
                    agencyName={resolveMultiLingual(
                      jobDetails?.agency_profile?.name,
                      LOCALE.JA
                    )}
                    jobTitle={resolveMultiLingual(
                      jobDetails?.job_profile?.job_title,
                      LOCALE.JA
                    )}
                    location={jobDetails?.job_profile?.job_overview?.location}
                    salary={jobDetails?.job_profile?.job_overview?.salary}
                    requiredSkills={jobDetails?.job_profile?.skills}
                    otherDetails={resolveMultiLingual(
                      jobDetails?.job_profile?.additional_information,
                      LOCALE.JA
                    )}
                    additionInfoFileDetails={jobAdditionalFileMetaData}
                    cvFileRequirements={
                      jobDetails?.job_profile?.cv_file_requirements
                    }
                    hiringFlow={jobDetails?.job_profile?.hiring_flow}
                  />
                )}
              </>
            </TabPanel>
          </Stack>
        </Stack>
      </Paper>
      <br />
      <Stack direction="row" justifyContent="space-between">
        {isJobDataLoading ? (
          <>
            <SkeletonButton />
            <SkeletonButton />
          </>
        ) : (
          <>
            <Button
              data-testid="job_additional_information_back_button"
              variant="outlined"
              startAdornment={<NavigateBeforeIcon />}
              handleClick={() =>
                navigate(
                  `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/${dataLocale}/hiring-process`
                )
              }>
              {intl.get("t_general_back")}
            </Button>
            <Button
              data-testid="job_additional_information_finish_button"
              handleClick={() => {
                if (jobId) {
                  handleSetJobData(
                    jobId,
                    { ...jobData, updated_at: Timestamp.now() },
                    handleJobUpdateSuccess,
                    handleJobUpdateFail
                  );
                }
              }}
              loading={isDisabled}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              endAdornment={<NavigateNextIcon />}>
              {intl.get("t_general_finish")}
            </Button>
          </>
        )}
      </Stack>
    </>
  );
};

export default Confirmation;

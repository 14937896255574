import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { NavigateBefore as NavigateBeforeIcon } from "@mui/icons-material";
import { Box, Grid, Stack, Typography } from "@mui/material";
import * as yup from "yup";

import SkeletonJobOverviewForm from "@skeletons/SkeletonJobOverviewForm";

import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import NonTypeableSelect from "@components/NonTypeableSelect";
import Paper from "@components/Paper";
import TextField from "@components/TextField";

import useUserProfile from "@hooks/database/useUserProfile";
import { useOptions } from "@hooks/useOptions";

import {
  FREE_TEXT_FIELD_MAX_LENGTH,
  LOCALE_SHORT,
  YEARS_OF_EXPERIENCE,
  YEARS_OF_EXPERIENCE_T_LABELS
} from "@utils/config";
import { intl } from "@utils/translate";

interface JobOverviewForm {
  jobTitle?: string;
  yearOfExperience?: typeof YEARS_OF_EXPERIENCE[keyof typeof YEARS_OF_EXPERIENCE];
  primaryJobTitle?: Array<number>;
}

interface JobOverviewFormProps {
  initialValues: JobOverviewForm;
  handleFormSubmit: (formData: JobOverviewForm) => void;
  backBtnLink?: string;
  isDisabled?: boolean;
  formLanguage?: typeof LOCALE_SHORT[keyof typeof LOCALE_SHORT];
}

const JobOverviewForm = ({
  initialValues,
  handleFormSubmit,
  backBtnLink,
  isDisabled = false,
  formLanguage = LOCALE_SHORT.JA
}: JobOverviewFormProps) => {
  const navigate = useNavigate();
  const { item_id } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const itemId = Number(item_id);
  const userData = useUserProfile();
  const userJobExperienceOverview =
    userData.value?.summary?.job_experience_overview ?? [];

  // validation schema
  const schema = yup.object({
    jobTitle: yup
      .string()
      .max(
        FREE_TEXT_FIELD_MAX_LENGTH,
        intl.get("t_error_max_limit", {
          field: intl.get("t_general_job_title"),
          maxLimit: FREE_TEXT_FIELD_MAX_LENGTH
        })
      )
      .trim()
      .required(
        intl.get("t_error_required", {
          field: intl.get("t_general_job_title")
        })
      ),
    yearOfExperience: yup.string().required(
      intl.get("t_error_required", {
        field: intl.get("t_profile_job_overview_year_of_experience")
      })
    )
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, control, setValue } = methods;
  useEffect(() => {
    setLoading(false);
  }, []);

  const YEARS_OF_EXPERIENCE_OPTIONS = useOptions(
    YEARS_OF_EXPERIENCE,
    YEARS_OF_EXPERIENCE_T_LABELS
  );

  if (loading || userData.loading) {
    return <SkeletonJobOverviewForm hasSingleField />;
  }

  return (
    <Box noValidate component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Paper>
        <Typography variant="h3">
          {intl.get("t_profile_job_overview_heading")}
        </Typography>
        <Typography mt={2.5} color="text.secondary">
          {intl.get("t_profile_job_overview_sub_heading")}
        </Typography>
        <Typography mb={3} color="text.secondary">
          {formLanguage === LOCALE_SHORT.JA
            ? intl.get("t_resumes_form_subtitle_lang_japanese")
            : intl.get("t_resumes_form_subtitle_lang_english")}
        </Typography>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={5.5}>
            <TextField
              data-testid="resumes_job_overview_job_title_input"
              disabled={isDisabled}
              control={control}
              name="jobTitle"
              label={intl.get("t_general_job_title")}
              placeholder={intl.get("t_general_job_title")}
              required
            />
          </Grid>
          <Grid item xs={12} md={5.5}>
            <NonTypeableSelect
              data-testid="resumes_job_overview_year_of_experience_select"
              disabled={isDisabled}
              setValue={setValue}
              control={control}
              name="yearOfExperience"
              label={intl.get("t_profile_job_overview_year_of_experience")}
              placeholder={intl.get(
                "t_profile_job_overview_year_of_experience"
              )}
              required
              options={YEARS_OF_EXPERIENCE_OPTIONS}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              data-testid="resumes_job_overview_primary_job_title_checkbox"
              setValue={setValue}
              size="medium"
              name="primaryJobTitle"
              control={control}
              disabled={userJobExperienceOverview?.length === 0 || isDisabled}
              options={[
                {
                  label: intl.get("t_profile_job_overview_primary_job_title"),
                  value: item_id ? itemId : userJobExperienceOverview?.length
                }
              ]}
            />
          </Grid>
        </Grid>
      </Paper>
      <Stack justifyContent="space-between" direction="row" mt={5.5}>
        {backBtnLink ? (
          <Button
            data-testid="resumes_job_overview_back_button"
            variant="outlined"
            startAdornment={<NavigateBeforeIcon />}
            handleClick={() => navigate(backBtnLink)}>
            {intl.get("t_general_back")}
          </Button>
        ) : (
          false
        )}
        <Button
          type="submit"
          loading={isDisabled}
          data-testid="resumes_job_overview_save_button">
          {intl.get("t_general_save")}
        </Button>
      </Stack>
    </Box>
  );
};

export default JobOverviewForm;

import { useEffect, useState } from "react";

import { DropResult } from "react-beautiful-dnd";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import {
  AddCircle as AddCircleIcon,
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon
} from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import SkeletonHiringProcessForm from "@skeletons/EmployersPostJob/SkeletonHiringProcessForm";
import SkeletonButton from "@skeletons/SkeletonButton";

import Button from "@components/Button";
import DragDropSelect from "@components/DragDropSelect";
import Paper from "@components/Paper";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import { useOptions } from "@hooks/useOptions";
import useToast from "@hooks/useToast";

import HiringFlow from "@interfaces/database/HiringFlow";
import JobID from "@interfaces/database/JobID";
import JobProfile from "@interfaces/database/JobProfile";

import {
  JOB_HIRING_FLOW_INTERVIEW,
  JOB_HIRING_FLOW_INTERVIEW_T_LABELS,
  JOB_HIRING_FLOW_OFFER,
  JOB_HIRING_FLOW_OFFER_T_LABELS,
  JOB_HIRING_FLOW_RESUME_SCREENING,
  JOB_HIRING_FLOW_RESUME_SCREENING_T_LABELS,
  MAX_INTERVIEW_STEPS,
  MAX_OFFER_STEPS,
  MAX_RESUME_SCREENING_STEPS,
  TOTAL_JOB_POSTING_STEPS
} from "@utils/config";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

interface HiringProcessFormData {
  resumeScreening: Array<{
    key?: string;
    type:
      | typeof JOB_HIRING_FLOW_RESUME_SCREENING[keyof typeof JOB_HIRING_FLOW_RESUME_SCREENING]
      | string;
  }>;
  interview: Array<{
    key?: string;
    type:
      | typeof JOB_HIRING_FLOW_INTERVIEW[keyof typeof JOB_HIRING_FLOW_INTERVIEW]
      | string;
  }>;
  offer: Array<{
    key?: string;
    type:
      | typeof JOB_HIRING_FLOW_OFFER[keyof typeof JOB_HIRING_FLOW_OFFER]
      | string;
  }>;
}

const HiringProcess = () => {
  const { job_id: jobId, data_locale: dataLocale } = useParams();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [resumeScreeningFieldIdsArr, setResumeScreeningFieldIdsArr] = useState<
    Array<string>
  >([]);
  const [interviewFieldIdsArr, setInterviewFieldIdsArr] = useState<
    Array<string>
  >([]);
  const [offerFieldIdsArr, setOfferFieldIdsArr] = useState<Array<string>>([]);
  const navigate = useNavigate();
  const toast = useToast();
  const companyDetails = useCompanyDetails();
  const {
    jobData,
    handleSetJobData
  }: {
    jobData: JobProfile;
    handleSetJobData: (
      jobId: JobID,
      jobData: JobProfile,
      handleJobUpdateSuccess: (jobId: string) => void,
      handleJobUpdateFail: () => void
    ) => void;
  } = useOutletContext();
  const isCompanyDetailsLoading = companyDetails.loading;

  const formInitValues = {
    resumeScreening: jobData?.hiring_flow?.resume_screening.steps ?? [
      { type: JOB_HIRING_FLOW_RESUME_SCREENING.RESUME_SCREENING }
    ],
    interview: jobData?.hiring_flow?.interview.steps ?? [
      { type: JOB_HIRING_FLOW_INTERVIEW.INTERVIEW }
    ],
    offer: jobData?.hiring_flow?.offer.steps ?? [
      { type: JOB_HIRING_FLOW_OFFER.OFFER }
    ]
  };

  const methods = useForm({
    defaultValues: formInitValues
  });

  const { handleSubmit, control, setValue, reset, getValues } = methods;

  const resumeScreening = useFieldArray({
    control,
    name: "resumeScreening"
  });
  const interview = useFieldArray({
    control,
    name: "interview"
  });
  const offer = useFieldArray({
    control,
    name: "offer"
  });

  const JOB_HIRING_FLOW_RESUME_SCREENING_OPTIONS = useOptions(
    JOB_HIRING_FLOW_RESUME_SCREENING,
    JOB_HIRING_FLOW_RESUME_SCREENING_T_LABELS
  );
  const JOB_HIRING_FLOW_INTERVIEW_OPTIONS = useOptions(
    JOB_HIRING_FLOW_INTERVIEW,
    JOB_HIRING_FLOW_INTERVIEW_T_LABELS
  );
  const JOB_HIRING_FLOW_OFFER_OPTIONS = useOptions(
    JOB_HIRING_FLOW_OFFER,
    JOB_HIRING_FLOW_OFFER_T_LABELS
  );

  useEffect(() => {
    reset(formInitValues);
  }, [jobData?.hiring_flow]);

  useEffect(() => {
    const resumeScreeningStepsLength = formInitValues.resumeScreening?.length;
    if (resumeScreeningStepsLength && resumeScreeningStepsLength > 0) {
      setResumeScreeningFieldIdsArr(
        Array.from({ length: resumeScreeningStepsLength }, () => uuidv4())
      );
    }
  }, [jobData?.hiring_flow?.resume_screening]);

  useEffect(() => {
    const interviewStepsLength = formInitValues.interview?.length;
    if (interviewStepsLength && interviewStepsLength > 0) {
      setInterviewFieldIdsArr(
        Array.from({ length: interviewStepsLength }, () => uuidv4())
      );
    }
  }, [jobData?.hiring_flow?.interview]);

  useEffect(() => {
    const offerStepsLength = formInitValues.offer?.length;
    if (offerStepsLength && offerStepsLength > 0) {
      setOfferFieldIdsArr(
        Array.from({ length: offerStepsLength }, () => uuidv4())
      );
    }
  }, [jobData?.hiring_flow?.offer]);

  const handleJobUpdateSuccess = (updatedJobId: string) => {
    setIsDisabled(false);
    navigate(
      `/${translate.getCurrentLocale()}/employers/jobs/${updatedJobId}/${dataLocale}/confirmation`
    );
  };

  const handleJobUpdateFail = () => {
    setIsDisabled(false);
    toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
  };

  const handleFormSubmit = (data: HiringProcessFormData) => {
    setIsDisabled(true);
    const hiringFlowData: HiringFlow = {
      resume_screening: {
        steps: data.resumeScreening
          .filter((singleResumeScreening) => singleResumeScreening.type)
          .map((singleResumeScreening) => {
            return {
              type: singleResumeScreening.type as typeof JOB_HIRING_FLOW_RESUME_SCREENING[keyof typeof JOB_HIRING_FLOW_RESUME_SCREENING],
              key: singleResumeScreening?.key
                ? singleResumeScreening.key
                : uuidv4()
            };
          })
      },
      interview: {
        steps: data.interview
          .filter((singleInterview) => singleInterview.type)
          .map((singleInterview) => {
            return {
              type: singleInterview.type as typeof JOB_HIRING_FLOW_INTERVIEW[keyof typeof JOB_HIRING_FLOW_INTERVIEW],
              key: singleInterview.key ? singleInterview.key : uuidv4()
            };
          })
      },
      offer: {
        steps: data.offer
          .filter((singleOffer) => singleOffer.type)
          .map((singleOffer) => {
            return {
              type: singleOffer.type as typeof JOB_HIRING_FLOW_OFFER[keyof typeof JOB_HIRING_FLOW_OFFER],
              key: singleOffer?.key ? singleOffer.key : uuidv4()
            };
          })
      }
    };

    try {
      if (companyDetails?.value && !companyDetails?.value?.jobs) {
        companyDetails.value.jobs = [];
      }
      if (jobId && companyDetails?.value?.jobs) {
        handleSetJobData(
          jobId,
          {
            ...jobData,
            hiring_flow: hiringFlowData,
            updated_at: Timestamp.now()
          },
          handleJobUpdateSuccess,
          handleJobUpdateFail
        );
      } else if (jobId) {
        handleJobUpdateSuccess(jobId);
      }
    } catch (e) {
      handleJobUpdateFail();
    }
  };

  const handleDragEnd = ({ source, destination }: DropResult) => {
    if (destination) {
      const { droppableId } = destination;
      if (droppableId === "resumeScreening") {
        resumeScreening.move(source.index, destination.index);
      } else if (droppableId === "interview") {
        interview.move(source.index, destination.index);
      } else if (droppableId === "offer") {
        offer.move(source.index, destination.index);
      }
    }
  };

  const handleDeleteField = (index: number, name: string) => {
    if (name === "resumeScreening") {
      setResumeScreeningFieldIdsArr((oldResumeScreeningFieldIdsArr) => {
        oldResumeScreeningFieldIdsArr.splice(index, 1);
        return [...oldResumeScreeningFieldIdsArr];
      });
      const fieldValues = getValues("resumeScreening");
      fieldValues.splice(index, 1);
      resumeScreening.replace(fieldValues);
    } else if (name === "interview") {
      setInterviewFieldIdsArr((oldInterviewFieldIdsArr) => {
        oldInterviewFieldIdsArr.splice(index, 1);
        return [...oldInterviewFieldIdsArr];
      });
      const fieldValues = getValues("interview");
      fieldValues.splice(index, 1);
      interview.replace(fieldValues);
    } else if (name === "offer") {
      setOfferFieldIdsArr((oldOfferFieldIdsArr) => {
        oldOfferFieldIdsArr.splice(index, 1);
        return [...oldOfferFieldIdsArr];
      });
      const fieldValues = getValues("offer");
      fieldValues.splice(index, 1);
      offer.replace(fieldValues);
    }
  };

  return (
    <Box noValidate component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Paper>
        <Stack direction={{ xs: "column", md: "row" }} gap={{ md: 10 }}>
          <Stack width={{ xs: "100%", md: "40%" }}>
            <Typography
              variant="subtitle2"
              mb={{ xs: 3, md: 8 }}
              color="secondary.main">
              {intl.get("t_general_step", { stepNumber: 7 })}/
              {TOTAL_JOB_POSTING_STEPS}
            </Typography>
            <Typography variant="h4" mb={2.5}>
              {intl.get("t_job_post_job_hiring_flow_set_the_process")}
            </Typography>
          </Stack>
          <Stack width={{ xs: "100%", md: "60%" }}>
            {/* resume screening section start */}
            {isCompanyDetailsLoading ? (
              <SkeletonHiringProcessForm />
            ) : (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between">
                  <Typography variant="h6">
                    {intl.get("t_general_resume_screening")}
                  </Typography>
                  <Button
                    color="primary"
                    handleClick={() => {
                      if (
                        resumeScreening.fields.length >=
                        MAX_RESUME_SCREENING_STEPS
                      ) {
                        toast.kampai(
                          intl.get("t_toast_error_limit_exceed", {
                            fieldName: intl.get("t_general_resume_screening"),
                            maxLimit: MAX_RESUME_SCREENING_STEPS
                          }),
                          "error"
                        );
                        return;
                      }
                      setResumeScreeningFieldIdsArr(
                        (oldResumeScreeningFieldIdsArr) => {
                          return [...oldResumeScreeningFieldIdsArr, uuidv4()];
                        }
                      );
                      return resumeScreening.append({
                        type: "" as typeof JOB_HIRING_FLOW_RESUME_SCREENING[keyof typeof JOB_HIRING_FLOW_RESUME_SCREENING],
                        key: ""
                      });
                    }}
                    size="small"
                    startAdornment={<AddCircleIcon />}
                    variant="text">
                    {intl.get("t_job_post_job_hiring_flow_add_step")}
                  </Button>
                </Stack>
                <DragDropSelect
                  disabled={isDisabled}
                  name="resumeScreening"
                  fields={
                    resumeScreening.fields as Array<{
                      id: string;
                      key: string;
                      type: string;
                    }>
                  }
                  fieldIdsArr={resumeScreeningFieldIdsArr}
                  handleDragEnd={handleDragEnd}
                  handleDeleteField={handleDeleteField}
                  control={control}
                  setValue={setValue}
                  options={JOB_HIRING_FLOW_RESUME_SCREENING_OPTIONS}
                />
                {/* resume screening section end */}

                {/* interview section start */}
                <Stack
                  mt={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between">
                  <Typography variant="h6">
                    {intl.get("t_general_interview")}
                  </Typography>
                  <Button
                    color="primary"
                    handleClick={() => {
                      if (interview.fields.length >= MAX_INTERVIEW_STEPS) {
                        toast.kampai(
                          intl.get("t_toast_error_limit_exceed", {
                            fieldName: intl.get(
                              "t_job_post_job_hiring_flow_interview"
                            ),
                            maxLimit: MAX_INTERVIEW_STEPS
                          }),
                          "error"
                        );
                        return;
                      }
                      setInterviewFieldIdsArr((oldInterviewFieldIdsArr) => {
                        return [...oldInterviewFieldIdsArr, uuidv4()];
                      });
                      return interview.append({
                        type: "" as typeof JOB_HIRING_FLOW_INTERVIEW[keyof typeof JOB_HIRING_FLOW_INTERVIEW],
                        key: ""
                      });
                    }}
                    size="small"
                    startAdornment={<AddCircleIcon />}
                    variant="text">
                    {intl.get("t_job_post_job_hiring_flow_add_step")}
                  </Button>
                </Stack>
                <DragDropSelect
                  disabled={isDisabled}
                  name="interview"
                  fields={interview.fields}
                  fieldIdsArr={interviewFieldIdsArr}
                  handleDragEnd={handleDragEnd}
                  handleDeleteField={handleDeleteField}
                  control={control}
                  setValue={setValue}
                  options={JOB_HIRING_FLOW_INTERVIEW_OPTIONS}
                />
                {/* interview section end */}

                {/* offer section start */}
                <Stack
                  mt={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between">
                  <Typography variant="h6">
                    {intl.get("t_general_offer")}
                  </Typography>
                  <Button
                    color="primary"
                    handleClick={() => {
                      if (offer.fields.length >= MAX_OFFER_STEPS) {
                        toast.kampai(
                          intl.get("t_toast_error_limit_exceed", {
                            fieldName: intl.get(
                              "t_job_post_job_hiring_flow_offer"
                            ),
                            maxLimit: MAX_OFFER_STEPS
                          }),
                          "error"
                        );
                        return;
                      }
                      setOfferFieldIdsArr((oldResumeOfferFieldIdsArr) => {
                        return [...oldResumeOfferFieldIdsArr, uuidv4()];
                      });
                      return offer.append({
                        type: "" as typeof JOB_HIRING_FLOW_OFFER[keyof typeof JOB_HIRING_FLOW_OFFER],
                        key: ""
                      });
                    }}
                    size="small"
                    startAdornment={<AddCircleIcon />}
                    variant="text">
                    {intl.get("t_job_post_job_hiring_flow_add_step")}
                  </Button>
                </Stack>
                <DragDropSelect
                  disabled={isDisabled}
                  name="offer"
                  fields={offer.fields}
                  fieldIdsArr={offerFieldIdsArr}
                  handleDragEnd={handleDragEnd}
                  handleDeleteField={handleDeleteField}
                  control={control}
                  setValue={setValue}
                  options={JOB_HIRING_FLOW_OFFER_OPTIONS}
                />
                {/* offer section end */}
              </>
            )}
          </Stack>
        </Stack>
      </Paper>

      <br />
      <Stack direction="row" justifyContent="space-between">
        {isCompanyDetailsLoading ? (
          <>
            <SkeletonButton />
            <SkeletonButton />
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              startAdornment={<NavigateBeforeIcon />}
              handleClick={() =>
                navigate(
                  `/${translate.getCurrentLocale()}/employers/jobs/${jobId}/${dataLocale}/additional-information`
                )
              }>
              {intl.get("t_general_back")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              endAdornment={<NavigateNextIcon />}>
              {intl.get("t_general_check_preview")}
            </Button>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default HiringProcess;
